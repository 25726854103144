import Client from "./Client";
import OfferType from "./OfferType";

export default class API {
  id?: number;
  name!: string;
  description?: string;
  code!: string;
  clientId: Client = new Client();
  offerTypeId: OfferType = new OfferType();
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
