
import { Component, Prop, Vue } from "vue-property-decorator";
import { Template } from "@/api/tharbad";
import TemplateForm from "./TemplateForm.vue";
import { CatalogItem } from "@/models/Catalog";
import { postTemplate } from "@/api/tharbad";
import Notify from "@/utils/notifications";

@Component({
  components: { TemplateForm },
})
export default class AddTemplateDrawer extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: "Nueva plantilla" }) title!: string;
  @Prop({ default: [] }) countries!: CatalogItem<string>[];

  fullscreenLoading = false;
  loading = false;
  itemData = {
    name: "",
    content: "",
    "country-code": "",
    sender: "",
  } as Template;

  get isVisible() {
    return this.show;
  }

  private getForm() {
    return (this.$refs["form"] as HTMLFormElement).$refs["form"];
  }

  closeDrawer() {
    this.loading = false;
    const form = this.getForm();
    form.resetFields();
    form.clearValidate();
    this.$emit("closeDrawer", false);
  }

  async validateForm() {
    const form = this.getForm();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      form.validate((valid: boolean) => {
        resolve(valid);
      });
    });
  }

  processItem() {
    this.validateForm()
      .then(async isValid => {
        if (this.itemData !== null && isValid) {
          try {
            this.fullscreenLoading = true;
            this.loading = true;
            await postTemplate(this.itemData);
            Notify.successful("Plantilla creada exitosamente.");
            this.$emit("updateView");
            this.$emit("closeDrawer", false);
          } catch (e) {
            if (e.data === undefined) {
              Notify.error((e as Error).message);
            } else {
              Notify.gebServerError(e as Error);
            }
          }
        }
      })
      .finally(() => {
        this.fullscreenLoading = false;
        this.loading = false;
      });
  }
}
