
import { Component, Prop, Vue } from "vue-property-decorator";
import { Shipment, findTemplates } from "@/api/tharbad";
import { CatalogItem } from "@/models/Catalog";
import _ from "underscore";
@Component({
  components: {},
})
export default class ShipmentForm extends Vue {
  @Prop() item!: Shipment;
  @Prop() templates!: CatalogItem<string>[];

  rules = {
    sender: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "max-size": [
      {
        required: false,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    content: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "schedule-date": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };

  templateId = null;

  async fillForm(event: Array<number>) {
    const templates = await findTemplates({ id: this.templateId || 0 });
    templates.rows = _.filter(templates.rows, t => {
      return t.id == this.templateId;
    });
    this.item.sender = templates.rows[0].sender;
    this.item.content = templates.rows[0].content;
  }
}
