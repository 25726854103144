import { Count, Gondor, Response } from "../Gondor";
import { CardItem } from "@/models/Card";

export interface Card {
  activeDays: any[];
  starred: boolean;
  id: number;
  b64Picture: string;
  order: null | number;
  active: boolean;
  startTimeActivity: null | string;
  endTimeActivity: null | string;
  subtitle: null | string;
  systemId: number;
  name: string;
  content: string;
  aside: null | string;
  updatedAt: Date;
  createdAt: Date;
  offerTypeId?: number;
}

const ACTIONS = {
  CARD: "/card/",
  CARD_ITEM: "/card/{id}/",
  CARD_ORDER: "/card/order/",
  UPLOAD: "/card/upload",
  CARD_ITEM_SETTING: "/card/{id}/setting",
  CARD_SETTING: "/cardsetting",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.CARD, { params });
}

export async function findOne(id: number | string) {
  id = id.toString();
  const response = await Gondor.API.get(ACTIONS.CARD_ITEM.replace("{id}", id));
  return response.data.data as CardItem;
}

export function create(body: object) {
  return Gondor.API.post<Response<Card>>(ACTIONS.CARD, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.CARD_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.CARD_ITEM.replace("{id}", id));
}

export function order(body: object) {
  return Gondor.API.patch(ACTIONS.CARD_ORDER, body);
}

export const findSettings = (apiSystemId: number) => {
  return Gondor.API.get<Response<Count<any>>>(
    ACTIONS.CARD_ITEM_SETTING.replace("{id}", String(apiSystemId))
  );
};

export const uploadImage = (imageFile: FormData) => {
  return Gondor.API.post<Response<{ url: string }>>(ACTIONS.UPLOAD, imageFile);
};

export const createSetting = (specificId: number, body: any) => {
  return Gondor.API.post(ACTIONS.CARD_SETTING, {
    ...body,
    cardId: specificId,
  });
};

export const updateSetting = (configId: number, body: any) => {
  return Gondor.API.patch(
    ACTIONS.CARD_ITEM_SETTING.replace("{id}", String(configId)),
    body
  );
};

export const destoySetting = (specificId: number) => {
  return Gondor.API.delete(
    ACTIONS.CARD_ITEM_SETTING.replace("{id}", String(specificId))
  );
};
