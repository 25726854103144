
import { Component, Vue } from "vue-property-decorator";
import VueGridLayout from "vue-grid-layout";
import Notify from "@/utils/notifications";
import _ from "underscore";
import Notification from "@/models/Notification";
import System from "@/models/System";
import * as NotificationServices from "@/api/helpers/Notification";
import NotificationTemplate from "@/components/NotificationTemplate.vue";
import NotificationDrawer from "@/components/TheNotificationDrawer.vue";
import DeletePopover from "@/components/DeletePopover.vue";
import * as SystemServices from "@/api/helpers/System";

@Component({
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    NotificationTemplate,
    DeletePopover,
    NotificationDrawer,
  },
})
export default class Notifications extends Vue {
  system = System;
  create = false;
  fullscreenLoading = true;
  notificationDrawer = false;
  systemId = 1;
  colSize = 4;
  dragWidth = 1;
  dragHeight = 10;
  notification = new Notification();
  activeNotifications: Array<Notification> = [];
  inactiveNotifications: Array<Notification> = [];
  activeSystems: Array<System> = [];
  systemName = "";
  layout: Array<object> = [];

  movedEvent(i: number, newX: number, newY: number) {
    const newIndex = this.getNewIndex(newX, newY);
    this.activeNotifications = _.compact(
      this.arrayMove(this.activeNotifications, i, newIndex)
    );
    const notificationsIds = _.map(
      this.activeNotifications,
      (c: Notification) => {
        return c.id;
      }
    );
    this.updateNotificationOrder(notificationsIds);
    this.buildGridLayout(this.activeNotifications);
  }

  getNewIndex(x: number, y: number) {
    if (y > 0) {
      y = Math.ceil(y / this.dragHeight) - 1;
    }
    return x + y * this.colSize;
  }

  arrayMove(array: Array<any>, oldIndex: number, newIndex: number) {
    if (newIndex >= array.length) {
      let k = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }

  buildGridLayout(array: Array<object>) {
    this.layout = [];
    let row = 0;
    let col = 0;
    _.each(array, (e: object, i: number) => {
      const item = {
        x: col,
        y: row,
        w: this.dragWidth,
        h: this.dragHeight,
        i,
      };
      this.layout.push(item);
      col += 1;
      if (col == this.colSize) {
        col = 0;
        row += this.dragHeight;
      }
    });
  }

  getNotifications() {
    const systemId = this.systemId;
    this.fullscreenLoading = true;
    const pickedSystem = _.find(this.activeSystems, (s: System) => {
      return s.id == systemId;
    });
    sessionStorage.setItem("systemId", systemId.toString());
    this.system = pickedSystem;
    if (pickedSystem) this.systemName = pickedSystem.name;
    NotificationServices.find({
      order: "order:asc",
      systemId: this.systemId,
    })
      .then(res => {
        const notifications = res.data.data;
        const groupednotifications = _.groupBy(
          notifications,
          (f: Notification) => {
            return f.active;
          }
        );
        this.activeNotifications = groupednotifications["true"] || [];
        this.inactiveNotifications = groupednotifications["false"] || [];
        this.buildGridLayout(this.activeNotifications);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  getSystems() {
    SystemServices.find({
      order: "name:asc",
      active: 1,
      showImprovement: 1,
    })
      .then(res => {
        this.activeSystems = res.data.data;
        if (this.activeSystems.length && this.activeSystems[0].id) {
          const systemId = sessionStorage.getItem("systemId");
          this.systemId = systemId
            ? parseInt(systemId)
            : this.activeSystems[0].id;
        }
        this.getNotifications();
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  updateNotificationOrder(notificationsOrder: Array<number | undefined>) {
    this.fullscreenLoading = true;
    NotificationServices.order({
      systemId: this.systemId,
      notification: notificationsOrder,
    })
      .then(() => {
        Notify.successful("Se modificó el orden de las notificaciones.");
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  deleteNotification(id: number) {
    this.fullscreenLoading = true;
    NotificationServices.destroy(id)
      .then(res => {
        this.getSystems();
        Notify.successful(`${res.data.data.name} eliminada exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  chooseNotification(index: number, type: string, active: boolean) {
    this.notification = active
      ? this.activeNotifications[index]
      : this.inactiveNotifications[index];
    this.create = false;
    this.notificationDrawer = true;
  }

  closeDrawer(value: boolean) {
    this.notificationDrawer = value;
  }

  openDrawer() {
    this.notification = new Notification();
    this.create = this.notificationDrawer = true;
  }

  created() {
    const index = "10";
    const title = "Notificaciones";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getSystems();
  }
}
