import { Gondor } from "../Gondor";

const ACTIONS = {
  VEHICLE_SIZE: "/vehicleSize/",
  VEHICLE_SIZE_ITEM: "/vehicleSize/{id}/",
  ADD_SYSTEMS: "/vehicleSize/add/system",
  REMOVE_SYSTEMS: "/vehicleSize/remove/system",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.VEHICLE_SIZE, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.VEHICLE_SIZE_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.VEHICLE_SIZE, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.VEHICLE_SIZE_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.VEHICLE_SIZE_ITEM.replace("{id}", id));
}

export function addSystems(body: object) {
  return Gondor.API.post(ACTIONS.ADD_SYSTEMS, body);
}

export function removeSystems(body: object) {
  return Gondor.API.post(ACTIONS.REMOVE_SYSTEMS, body);
}
