
import { Component, Prop, Vue } from "vue-property-decorator";
import Regex from "@/utils/regex";
import * as PermissionServices from "@/api/helpers/Permission";
import Permission from "@/models/Permission";
import Notify from "@/utils/notifications";

@Component
export default class PermissionDrawer extends Vue {
  @Prop() permission!: Permission;
  @Prop() create!: boolean;
  @Prop() show!: boolean;

  loading = false;
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    code: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        pattern: Regex.code,
        message: "El código debe respetar el formato `model.action`.",
      },
    ],
  };

  createPermission() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        PermissionServices.create(this.permission)
          .then(() => {
            this.$emit("newPermission");
            Notify.successful("Permiso creado exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updatePermission() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.permission && this.permission.id)
          PermissionServices.update(this.permission.id, this.permission)
            .then(() => {
              Notify.successful("Permiso actualizado exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    return this.create ? "Crear nuevo permiso" : "Actualizar permiso";
  }

  get showDrawer() {
    return this.show;
  }
}
