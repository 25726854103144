
import { Component, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import LenderDrawer from "@/components/Liink/LenderDrawer.vue";
import LenderCard from "@/components/Liink/LenderCard.vue";
import Liink from "@/api/Liink";
import Lender from "@/models/Liink/Lender";

@Component({
  components: {
    LenderCard,
    LenderDrawer,
  },
})
export default class CatalogView extends Vue {
  loading = false;
  loadingDrawer = false;
  lender: Lender = {} as Lender;
  lenders: Array<Lender> = [];
  drawer = false;
  create = false;

  async createLender(data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.createLender(data);

      Notify.successful("El lender se creó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async updateLender(id: number, data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.updateLender(id, data);

      Notify.successful("El lender se actualizó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async deleteLender(id: number) {
    try {
      await Liink.deleteLender(id);

      Notify.successful("El lender se eliminó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.load();
  }

  openDrawer(lender?: Lender) {
    this.create = lender == undefined;
    this.lender = lender ? lender : ({} as Lender);
    this.drawer = true;
  }

  closeDrawer() {
    this.loadingDrawer = false;
    this.drawer = false;
    this.lender = {} as Lender;
  }

  async load() {
    this.loading = true;

    try {
      this.lenders = await Liink.getLenders();
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  created() {
    const title = "Clientes";
    const index = "5.2";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Liink",
        to: "/liink",
      },
      {
        text: title,
      },
    ]);

    this.load();
  }
}
