
import { Component, Vue } from "vue-property-decorator";
import LandingDrawer from "@/components/TheLandingDrawer.vue";
import * as LandingServices from "@/api/helpers/Landing";
import * as SystemServices from "@/api/helpers/System";
import Landing from "@/models/Landing";
import System from "@/models/System";
import Notify from "@/utils/notifications";
import DeletePopover from "@/components/DeletePopover.vue";
import CKEditor from "ckeditor4-vue";

@Component({
  components: {
    LandingDrawer,
    DeletePopover,
    ckeditor: CKEditor.component,
  },
})
export default class LandingView extends Vue {
  fullscreenLoading = true;
  activeSystems: Array<System> = [];
  landings: Array<Landing> = [];
  landing = new Landing();
  system = new System();
  loading = false;
  create = false;
  showDrawer = false;
  title = "";
  collapse = true;
  hover = false;
  systemId = 1;

  editDrawer() {
    this.title = "Actualizar landing";
    this.create = false;
    this.showDrawer = true;
  }

  newDrawer() {
    this.title = "Crear nueva landing";
    this.create = true;
    this.landing = new Landing();
    this.showDrawer = true;
  }

  closeDrawer() {
    this.loading = false;
    this.showDrawer = false;
  }

  copyToClipboard(code: string) {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = code;
    input.select();
    document.execCommand("copy", false);
    input.remove();
    Notify.successful("Código copiado en el portapapeles.");
  }

  pickLanding(index: number) {
    this.landing = this.landings[index];
    this.collapse = false;
  }

  deleteLanding(id: number) {
    LandingServices.destroy(id)
      .then(res => {
        this.fullscreenLoading = false;
        if (id == this.landing.id) this.landing = new Landing();
        Notify.successful(`${res.data.data.name} eliminada exitosamente.`);
        this.getLandings();
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  updateLanding(id: number) {
    this.loading = true;
    LandingServices.update(id, this.landing)
      .then(() => {
        this.loading = false;
        this.showDrawer = false;
        Notify.successful(`Landing actualizada exitosamente.`);
        this.getLandings();
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  createLanding() {
    this.loading = true;
    this.landing.systemId = this.systemId;
    LandingServices.create(this.landing)
      .then(res => {
        this.loading = false;
        this.showDrawer = false;
        Notify.successful(`Landing creada exitosamente.`);
        this.getLandings(res.data.data.id);
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getLandings(id?: number) {
    const systemId = this.systemId;
    const systemIdStoraged = sessionStorage.getItem("systemId") || "";

    if (systemId.toString() != systemIdStoraged.toString()) {
      sessionStorage.setItem("systemId", systemId.toString());
      this.landing = new Landing();
    }
    const system = this.activeSystems.find((s: System) => s.id == systemId);
    if (system) this.system = system;
    this.fullscreenLoading = true;
    LandingServices.find({
      order: "name:asc",
      system: this.systemId,
    })
      .then(res => {
        this.landings = res.data.data;
        if (id) {
          const landing = this.landings.find((l: Landing) => l.id == id);
          if (landing) {
            this.landing = landing;
            this.collapse = false;
          }
        }
        this.fullscreenLoading = false;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getSystems() {
    SystemServices.find({
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.activeSystems = res.data.data;
        if (this.activeSystems.length && this.activeSystems[0].id) {
          const systemId = sessionStorage.getItem("systemId");
          this.systemId = systemId
            ? parseInt(systemId)
            : this.activeSystems[0].id;
        }
        this.getLandings();
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  created() {
    const index = "8";
    const title = "Landings";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getSystems();
  }
}
