import { Gondor } from "../api/Gondor";

export interface State {
  currentTitle: string;
  breadcrumbItems: Array<object>;
  status: string;
  authenticated: boolean;
  collapseSidebar: boolean;
  currentMenuIndex: string;
}

const STATE: State = {
  currentTitle: "",
  breadcrumbItems: [],
  status: "",
  authenticated: Gondor.SESSION.isAuthenticated(),
  collapseSidebar: false,
  currentMenuIndex: "",
};

export default STATE;
