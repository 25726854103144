
import { Component, Prop, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import Lender from "@/models/Liink/Lender";
import DebtRule from "@/models/Liink/DebtRule";
import FinancialInstitution from "@/models/Liink/FinancialInstitution";

@Component({ components: { DeletePopover } })
export default class DebtRuleCard extends Vue {
  @Prop() rules!: Array<DebtRule>;
  @Prop() lenders!: Array<Lender>;
  @Prop() financialInstitutions!: Array<FinancialInstitution>;

  deleteRule(id: number) {
    this.$emit("delete", id);
  }

  updateRule(rule: DebtRule) {
    this.$emit("update", rule);
  }

  createRule() {
    this.$emit("create");
  }

  lender(id: number) {
    const lender = this.lenders.find(lender => lender.id == id);

    if (!lender) return "-";

    return lender.name;
  }

  financialInstitution(id: number) {
    const financialInstitution = this.financialInstitutions.find(
      financialInstitution => financialInstitution.id == id
    );

    if (!financialInstitution) return "-";

    return financialInstitution.name;
  }
}
