
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ConfigDrawer from "@/components/ConfigDrawer.vue";
import ApiSystemRules from "@/components/TheApiSystemRules.vue";
import { ElForm } from "element-ui/types/form";
import Regex from "@/utils/regex";
import Notify from "@/utils/notifications";
import ClientModel from "@/models/Client";
import * as ClientServices from "@/api/helpers/Client";
import * as OfferTypeServices from "@/api/helpers/OfferType";
import * as SystemServices from "@/api/helpers/System";
import * as ApiSystemServices from "@/api/helpers/ApiSystem";
import * as ApiRuleServices from "@/api/helpers/ApiRule";
import * as ApiSettingServices from "@/api/helpers/ApiSetting";
import * as ColumnServices from "@/api/helpers/Column";
import APISystem from "@/models/APISystem";

interface FormRule {
  required?: boolean;
  type?: string;
  min?: number;
  max?: number;
  validator?: (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => void;
  trigger?: "blur" | "change";
  pattern?: RegExp;
  message: string;
}

@Component({
  components: {
    ConfigDrawer,
    ApiSystemRules,
  },
})
export default class APIForm extends Vue {
  @Prop({ required: true }) apiSystem!: APISystem;
  @Prop({ required: true }) create!: boolean;

  systems: SystemServices.System[] = [];
  clients: ClientServices.Client[] = [];
  offerTypes: OfferTypeServices.OfferType[] = [];
  activeUpload = true;
  modalClient = false;
  client = new ClientModel();
  creatingClient = false;

  rules: { [key: string]: FormRule[] } = {
    "apiId.name": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    "apiId.code": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
      {
        message: "El campo debe estar en minúsculas.",
        validator(rule, value, callback) {
          if (value && value !== value.toLowerCase()) {
            callback(rule.message);
          }
          callback();
        },
      },
      {
        message: "El campo no debe contener espacios en blanco.",
        pattern: Regex.blankspace,
      },
    ],
    leadOffer: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        message: "El campo debe ser numérico",
        pattern: Regex.float,
      },
    ],
    redirectUrl: [
      {
        required: false,
        message:
          "No es una url válida, debe respetar el formato http://www.url.com o similar.",
        trigger: "blur",
        pattern: Regex.url,
      },
    ],
    "apiId.clientId.id": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "change",
      },
    ],
    "apiId.offerTypeId.id": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "change",
      },
    ],
    "systemId.id": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "change",
      },
    ],
  };

  rulesClient: { [key: string]: FormRule[] } = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };

  createClient(formName: string) {
    this.creatingClient = true;
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid) {
        ClientServices.create(this.client)
          .then(() => {
            Notify.successful(`Creado exitosamente.`);
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.creatingClient = false;
            this.getClients();
            this.handleModalClient(false);
          });
      } else {
        this.creatingClient = false;
        return false;
      }
    });
  }

  handleModalClient(status: boolean) {
    this.modalClient = status;
  }

  newClient() {
    this.client = new ClientModel();
    this.handleModalClient(true);
  }

  created() {
    this.getClients();
    this.getSystems();
  }

  getClients() {
    ClientServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.clients = res.data.data.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getOfferTypes(apiSystem: number) {
    SystemServices.offerType(apiSystem)
      .then(res => {
        this.offerTypes = res.data.data.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getSystems() {
    SystemServices.find({
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.systems = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  apiSystemRules: ApiRuleServices.ApiRule[] = [];
  showRules = false;

  getRules(apiSystemId: number) {
    ApiSystemServices.findRules(apiSystemId).then(res => {
      this.apiSystemRules = res.data.data.rows;
    });
  }

  getColumns(apiSystemId: number) {
    ColumnServices.find({
      order: "name",
      system: apiSystemId,
    })
      .then(res => {
        this.systemColumns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  handleShowRules(status: boolean) {
    this.showRules = status;
  }

  validateForm() {
    return (this.$refs.APIForm as ElForm).validate();
  }

  apiSettings: ApiSettingServices.ApiSetting[] = [];
  systemColumns: ColumnServices.Column[] = [];
  selectedSystem = 0;
  loadingDrawer = false;
  showDrawer = false;

  handleDrawer(status: boolean) {
    this.showDrawer = status;
  }

  getApiSettings(selected: number) {
    this.loadingDrawer = true;
    ApiSystemServices.findSettings(selected)
      .then(res => {
        this.apiSettings = res.data.data.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loadingDrawer = false;
      });
  }

  @Watch("apiSystem", { immediate: true })
  onSystemChange(newValue: APISystem) {
    if (newValue.id) {
      this.selectedSystem = newValue.id;
      this.getApiSettings(newValue.id);
      this.getRules(newValue.id);
      this.getColumns(newValue.systemId.id as number);
      this.getOfferTypes(newValue.systemId.id as number);
    }
  }
}
