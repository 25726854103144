import { Gondor } from "../Gondor";

const ACTIONS = {
  RULE: "/rule/",
  RULE_ITEM: "/rule/{id}/",
};

export function create(body: object) {
  return Gondor.API.post(ACTIONS.RULE, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.RULE_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.RULE_ITEM.replace("{id}", id));
}
