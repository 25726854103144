import System from "@/models/System";
import API from "@/models/API";

export default class APISystem {
  id?: number;
  active = true;
  leadOffer = 0;
  redirectUrl?: string;
  apiId: API = new API();
  systemId: System = new System();
  pingtree = false;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
