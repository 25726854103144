import { Gondor } from "../Gondor";

interface Response<T> {
  code: string;
  message: string;
  data: T[];
}

export interface Product {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  ProductSystems: System[];
  systems: System[];
}

interface System {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  productId: number;
  systemId: number;
  System: SystemClass;
}

interface SystemClass {
  id: number;
  name: string;
  url: string;
  keyId: string;
  keySecret: string;
  active: boolean;
  showImprovement: boolean;
  createdAt: Date;
  updatedAt: Date;
  countryId: number;
}

const ACTIONS = {
  PRODUCT: "/product/",
  PRODUCT_ITEM: "/product/{id}/",
  ADD_SYSTEMS: "/product/add/system",
  REMOVE_SYSTEMS: "/product/remove/system",
};

export function find(params?: object) {
  return Gondor.API.get<Response<Product>>(ACTIONS.PRODUCT, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.PRODUCT_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.PRODUCT, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.PRODUCT_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.PRODUCT_ITEM.replace("{id}", id));
}

export function addSystems(body: object) {
  return Gondor.API.post(ACTIONS.ADD_SYSTEMS, body);
}

export function removeSystems(body: object) {
  return Gondor.API.post(ACTIONS.REMOVE_SYSTEMS, body);
}
