
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import echarts from "echarts";

interface DataValue {
  name: string;
  stack: string;
  values: Array<number>;
}

export interface BarValues {
  values: Array<DataValue>;
  legends: Array<string>;
  categories: Array<string>;
}

@Component
export default class CardBars extends Vue {
  @Prop() name!: string;
  @Prop() eventName!: string;
  @Prop() displaySystems!: string;
  @Prop() systems!: Array<object>;
  @Prop() barsData!: BarValues;

  showNotFound = false;

  styleEmpty = {
    height: "0px",
    width: "100%",
  };

  styleChart = {
    width: "100%",
    height: "400px",
  };

  visible = false;
  loading = true;
  dateRange = [
    new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
  ];
  pickerOptions = [];
  systemsIds = [];
  myChart!: echarts.ECharts;
  // specify chart configuration item and data
  option: echarts.EChartOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      left: "center",
      bottom: "10",
      data: this.legends,
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      data: this.categories,
    },
    series: this.series,
  };

  displayEmpty(flag: boolean) {
    this.showNotFound = flag;
    if (!flag) {
      this.styleChart.height = "400px";
      this.styleEmpty.height = "0px";
    } else {
      this.styleChart.height = "0px";
      this.styleEmpty.height = "400px";
    }
  }

  get legends() {
    return this.barsData.legends;
  }

  get categories() {
    return this.barsData.categories;
  }

  get dataValues() {
    return this.barsData.values;
  }

  get series() {
    const data = [];
    for (const item of this.barsData.values) {
      data.push(this.createSeries(item.name, item.stack, item.values));
    }
    if (data.length === 0) {
      return [
        {
          name: "-",
          type: "bar",
          stack: "000",
          data: [],
        },
      ];
    }
    return data;
  }

  createSeries(name: string, stack: string, data: Array<number>) {
    return {
      name: name,
      type: "bar",
      stack: stack,
      data: data,
    };
  }

  renderChart() {
    return (
      this.option.series &&
      this.option.series.length > 0 &&
      this.option.series[0].data &&
      this.option.series[0].data.length > 0
    );
  }

  updateChart() {
    if (this.option.yAxis) {
      this.option.yAxis = {
        type: "category",
        data: this.categories,
      };
    }
    if (this.option.legend) {
      this.option.legend.data = this.legends;
    }
    if (this.option.series) {
      this.option.series = this.series;
    }
    this.myChart.clear();
    this.loading = false;
    if (this.renderChart()) {
      this.displayEmpty(false);
      this.myChart.setOption(this.option);
    } else {
      this.displayEmpty(true);
    }
  }

  apply() {
    this.visible = false;
    this.loading = true;
    this.displayEmpty(false);
    this.myChart.clear();
    const query = {
      start:
        this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null,
      end:
        this.dateRange && this.dateRange.length > 1 ? this.dateRange[1] : null,
      system: this.systemsIds.length > 0 ? this.systemsIds.toString() : null,
    };
    this.$emit(this.eventName, query, () => {
      this.updateChart();
      this.visible = true;
    });
  }

  mounted() {
    this.myChart = echarts.init(
      document.getElementById(this.name) as HTMLDivElement
    );
    this.displayEmpty(false);
    if (this.renderChart()) {
      this.myChart.setOption(this.option);
      this.loading = false;
    }
  }

  @Watch("barsData.categories")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPropertyChanged(value: Array<string>, oldValue: Array<string>) {
    this.updateChart();
  }
}
