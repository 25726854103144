
import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import OfferForm from "./OfferForm.vue";
import * as SystemServices from "@/api/helpers/System";
import * as OfferTypeServices from "@/api/helpers/OfferType";
import * as ColumnService from "@/api/helpers/Column";
import { Count } from "@/api/Gondor";
import OfferType from "@/models/OfferType";
import Notify from "@/utils/notifications";
import { AxiosResponse } from "axios";

interface System {
  id: number;
  name: string;
  url: string;
  keyId: string;
  active: boolean;
  showImprovement: boolean;
  createdAt: string;
  updatedAt: string;
  countryId: number;
}

@Component({
  components: {
    OfferForm,
  },
})
export default class OfferDrawer extends Vue {
  @Prop({ required: true }) show!: boolean;
  @Prop({ required: true }) system!: System;
  @Ref() readonly formRef!: OfferForm;

  systemOffers: Count<SystemServices.SystemOffer> = { count: 0, rows: [] };
  loading = false;
  loadingForm = false;
  create = true;

  offer = new OfferType();
  showDialog = false;

  columnsToDelete: (() => Promise<AxiosResponse<any>>)[] = [];

  createOffer() {
    this.offer = new OfferType();
    this.handleDialog(true);
    this.create = true;
  }

  editOffer(offerId: number) {
    const offerEdit = this.systemOffers.rows.find(
      offer => offer.id === offerId
    );
    if (offerEdit) {
      this.offer = {
        id: offerEdit.id,
        name: offerEdit.name,
        createdAt: offerEdit.createdAt,
        customParams: offerEdit.customParams,
        description: offerEdit.description as string | undefined,
        columns: offerEdit.columns,
        updatedAt: offerEdit.updatedAt,
      };
      this.handleDialog(true);
      this.create = false;
    }
  }

  newOfferType() {
    this.loadingForm = true;
    this.formRef
      .validateForm()
      .then(() => {
        const { name, customParams, description } = this.offer;
        OfferTypeServices.create({ name, customParams, description })
          .then(res => {
            const offerTypeId = res.data.data.id;
            const validColumns = this.offer.columns.filter(
              column => column.id !== undefined
            );
            const columnsPromise = validColumns.map(column => {
              return OfferTypeServices.addColumn({
                columnId: column.id as number,
                offerTypeId: offerTypeId,
              });
            });

            //Add promise to create relation OfferType - System
            columnsPromise.push(
              OfferTypeServices.addSystem({
                offerTypeId,
                systemId: this.system.id,
              })
            );

            Promise.all(columnsPromise)
              .then(() => {
                Notify.successful(`Oferta creada exitosamente.`);
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.loadingForm = false;
                this.handleDialog(false);
                this.findOffers(this.system.id);
              });
          })
          .catch(error => {
            Notify.gebServerError(error);
            this.loadingForm = false;
          });
      })
      .catch(() => {
        this.loadingForm = false;
      });
  }

  updateOfferType() {
    this.loadingForm = true;
    this.formRef
      .validateForm()
      .then(() => {
        const { name, customParams, description, id } = this.offer;
        if (id !== undefined) {
          const promiseArray = this.columnsToDelete.map(deleteFn => deleteFn());
          const columnsToCreate = this.offer.columns.filter(
            column =>
              column.offerTypeColumnId === undefined && column.id !== undefined
          );

          const columnsPromise = columnsToCreate.map(column => {
            return OfferTypeServices.addColumn({
              columnId: column.id as number,
              offerTypeId: id,
            });
          });

          OfferTypeServices.update(id, {
            name,
            customParams,
            description,
          })
            .then(() => {
              Promise.all([promiseArray, columnsPromise])
                .then(() => {
                  Notify.successful(`Oferta actualizada exitosamente.`);
                })
                .catch(error => {
                  Notify.gebServerError(error);
                })
                .finally(() => {
                  this.loadingForm = false;
                  this.handleDialog(false);
                  this.findOffers(this.system.id);
                  this.columnsToDelete = [];
                });
            })
            .catch(error => {
              Notify.gebServerError(error);
              this.loadingForm = false;
              this.columnsToDelete = [];
            });
        }
      })
      .catch(() => {
        this.loadingForm = false;
      });
  }

  deleteColumn(columnId: number) {
    if (!this.create) {
      this.columnsToDelete.push(() => OfferTypeServices.deleteColumn(columnId));
    }
  }

  handleDialog(status: boolean) {
    this.showDialog = status;
  }

  columnHasId(column: ColumnService.Column): boolean {
    return "id" in column;
  }

  closeDrawer() {
    this.$emit("closeDrawer", true);
  }

  findOffers(systemId: number) {
    this.loading = true;
    SystemServices.offerType(systemId)
      .then(res => {
        this.systemOffers = res.data.data;
        console.log(this.systemOffers);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Watch("system.id", { immediate: true })
  onSystemChange(newSystemId: number) {
    if (newSystemId) {
      this.findOffers(newSystemId);
    }
  }

  get titleDrawer() {
    return `Ofertas ${this.system.name}`;
  }

  get titleDialog() {
    return `${this.create ? "Crear" : "Actualizar"} oferta`;
  }
}
