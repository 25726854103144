export default class Faq {
  id?: number;
  question!: string;
  answer!: string;
  order?: number;
  active = true;
  createdAt?: Date;
  systemId!: number;
  lastUpdate?: Date;
  updatedAt?: Date;
}

export interface FaqItem extends Omit<Faq, "order"> {
  order: number | null;
}
