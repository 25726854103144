
import { Component, Prop, Vue } from "vue-property-decorator";
import System from "@/models/System";
import Faq from "@/models/Faq";
import * as FaqServices from "@/api/helpers/Faq";
import Notify from "@/utils/notifications";
import _ from "underscore";

@Component
export default class FaqDrawer extends Vue {
  @Prop() faq!: Faq;
  @Prop() create!: boolean;
  @Prop() show!: boolean;
  @Prop() loading!: boolean;
  @Prop() system!: System;

  rules = {
    question: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    answer: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };

  createFaq() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        this.faq.systemId = this.system.id;
        FaqServices.create(this.faq)
          .then(() => {
            this.$emit("newFaq");
            Notify.successful("Pregunta creada exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateFaq() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        if (this.faq && this.faq.id) {
          const params = _.pick(this.faq, "question", "answer", "active");
          FaqServices.update(this.faq.id, params)
            .then(() => {
              Notify.successful("Pregunta actualizada exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.$emit("newFaq");
              this.closeDrawer();
            });
        }
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  get title() {
    return this.create ? "Crear pregunta" : "Actualizar pregunta";
  }

  get showDrawer() {
    return this.show;
  }
}
