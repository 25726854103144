
import { Vue, Component, Prop } from "vue-property-decorator";
import DeletePopover from "./DeletePopover.vue";
import ConfigForm from "./ConfigForm.vue";
import { Setting, Type } from "@/api/helpers/Api";

@Component({
  components: {
    DeletePopover,
    ConfigForm,
  },
})
export default class ConfigDrawer extends Vue {
  @Prop({ required: true }) readonly model!: Setting[];
  @Prop({ required: true }) readonly modelId!: number;
  @Prop({ required: true }) readonly showDrawer!: boolean;
  @Prop({ required: true }) readonly type!: Type;
  @Prop({ required: true }) readonly loading!: boolean;

  closeDrawer() {
    this.$emit("closeDrawer", false);
  }

  updateModel(status: boolean) {
    this.$emit("updateModel", status);
  }

  get title() {
    const types: {
      [key in Type]: string;
    } = {
      api: "api",
      card: "tarjeta",
    };
    return types[this.type];
  }
}
