
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment-timezone";
import { JourneyItem } from "@/models/Journey";
import { CatalogItem } from "@/models/Catalog";
import DraggableJourneySteps from "@/components/sms/DraggableJourneySteps.vue";
import JourneyStepFormDrawer from "@/components/sms/JourneyStepFormDrawer.vue";

@Component({
  components: {
    DraggableJourneySteps,
    JourneyStepFormDrawer,
  },
})
export default class JourneyForm extends Vue {
  @Prop() item!: JourneyItem;
  @Prop() countries!: CatalogItem<string>[];

  innerDrawer = false;
  rules = {
    "schedule-time": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "shipment-max-size": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        type: "number",
        min: 0,
        message: "Debe ser in valor mayor o igual a 0",
        trigger: "blur",
      },
    ],
    active: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "country-code": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        length: 2,
        message: "Debe estar en formato ISO 3166-1-Alfa-2.",
      },
    ],
  };
  stepsRules = {
    stepsCount: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        type: "number",
        min: 1,
        message: "Debe por lo menos incluir un paso",
        trigger: "blur",
      },
    ],
  };
  scheduleTime = "";

  get formItems() {
    return { stepsCount: this.item.steps?.length || 0 };
  }

  openDrawer() {
    this.innerDrawer = true;
  }

  closeDrawer(value: boolean) {
    this.innerDrawer = value;
  }

  updateSteps(step: NonNullable<JourneyItem["steps"]>[0]) {
    if (this.item.steps !== undefined) {
      const lastOrder =
        this.item.steps.length > 0
          ? Math.max(...this.item.steps.map(x => x.order))
          : -1;
      this.item.steps.push({ ...step, order: lastOrder + 1 });
    }
  }

  updateScheduleTime() {
    if (this.scheduleTime) {
      const [hours, minutes] = this.scheduleTime.split(":");
      this.item["schedule-time"] = new Date(
        new Date().setHours(hours, minutes)
      );
    }
  }

  mounted() {
    if (this.item["schedule-time"]) {
      this.scheduleTime = moment(this.item["schedule-time"]).format("HH:mm");
    }
  }
}
