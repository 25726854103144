
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import echarts from "echarts";

@Component
export default class CardChart extends Vue {
  @Prop() name!: string;
  @Prop() systems!: Array<object>;
  @Prop() chartLegend!: Array<string>;
  @Prop() chartData!: Array<object>;

  showNotFound = false;

  styleEmpty = {
    height: "0px",
    width: "100%",
  };

  styleChart = {
    width: "100%",
    height: "400px",
  };

  visible = false;
  loading = true;
  dateRange = [
    new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
  ];
  pickerOptions = [];
  systemsIds = [];
  myChart!: echarts.ECharts;
  // specify chart configuration item and data
  option: echarts.EChartOption = {
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c} ({d}%)",
    },
    legend: {
      left: "center",
      bottom: "10",
      data: this.legends,
    },
    series: [
      {
        type: "pie",
        roseType: "radius",
        radius: [15, 95],
        center: ["50%", "38%"],
        data: this.dataValues,
        animationEasing: "cubicInOut",
        animationDuration: 2600,
      },
    ],
  };

  displayEmpty(flag: boolean) {
    this.showNotFound = flag;
    if (!flag) {
      this.styleChart.height = "400px";
      this.styleEmpty.height = "0px";
    } else {
      this.styleChart.height = "0px";
      this.styleEmpty.height = "400px";
    }
  }

  get legends() {
    return this.chartLegend;
  }

  get dataValues() {
    return this.chartData;
  }

  renderChart() {
    return (
      this.option.series &&
      this.option.series.length > 0 &&
      this.option.series[0].data &&
      this.option.series[0].data.length > 0
    );
  }

  updateChart() {
    if (this.option.legend) {
      this.option.legend.data = this.legends;
    }
    if (this.option.series) {
      this.option.series[0].data = this.dataValues;
    }
    this.myChart.clear();
    this.loading = false;
    if (this.renderChart()) {
      this.displayEmpty(false);
      this.myChart.setOption(this.option);
    } else {
      this.displayEmpty(true);
    }
  }

  apply() {
    this.visible = false;
    this.loading = true;
    this.displayEmpty(false);
    this.myChart.clear();
    const query = {
      start:
        this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null,
      end:
        this.dateRange && this.dateRange.length > 1 ? this.dateRange[1] : null,
      systemId: this.systemsIds,
    };
    this.$emit("ClickCard", query, () => {
      this.updateChart();
      this.visible = true;
    });
  }

  mounted() {
    this.myChart = echarts.init(
      document.getElementById(this.name) as HTMLDivElement
    );
    this.displayEmpty(false);
    if (this.renderChart()) {
      this.myChart.setOption(this.option);
      this.loading = false;
    }
  }

  @Watch("chartLegend")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPropertyChanged(value: Array<string>, oldValue: Array<string>) {
    this.updateChart();
  }
}
