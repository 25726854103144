import Rule from "@/models/Rule";
import System from "@/models/System";

export interface Setting<T = string | number[]> {
  id: number;
  key: string;
  value: T;
  name: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  specificId: number; //<-- cardId or apiSystemId
}

export interface OfferType {
  id: number;
  name: string;
}

export default class Card {
  id?: number;
  name!: string;
  content!: string;
  aside?: string;
  b64Picture = "";
  order?: number;
  active = true;
  rules: Array<Rule> = [];
  urls: Array<any> = [];
  url?: string;
  activeDays?: number[] = [];
  startTimeActivity?: string = "";
  endTimeActivity?: string = "";
  systemId!: number;
  createdAt?: Date;
  updatedAt?: Date;
  subtitle?: string = "";
  starred?: boolean;
  system: System = new System();
  offerType?: OfferType;
  showImprovement?: boolean;
}

export interface CardItem
  extends Omit<
    Card,
    | "aside"
    | "activeDays"
    | "order"
    | "startTimeActivity"
    | "endTimeActivity"
    | "urls"
  > {
  system: System;
  aside: string | null;
  activeDays: number[];
  order: number | null;
  startTimeActivity: string | null;
  endTimeActivity: string | null;
}

export interface CardCost {
  clicks: number;
  investment: number;
}
