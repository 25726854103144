import Contributor from "@/models/Contributor";
import * as DeviceClass from "@/models/Device";
import RhovanionClient from "./rhovanion_client";

enum Service {
  Device = "/device",
  DeviceItem = "/device/{device-id}",
  // DeviceUpdate = "/device/{device-id}",
  // DeviceDestroy = "/device/{device-id}",
  DeviceAssign = "/device/{device-id}/assign",
  DeviceUnassign = "/device/{device-id}/unassign",
  DeviceLocation = "/device-location",
  DeviceLocationItem = "/device-location/{device-id}",
  DeviceBrand = "/device-brand",
  DeviceBrandItem = "/device-brand/{device-id}",
  DeviceCategory = "/device-category",
  DeviceCategoryItem = "/device-category/{device-id}",
  DeviceStatus = "/device-status",
  DeviceStatusItem = "/device-status/{device-id}",
  Contributor = "/user",
  ContributorItem = "/user/{user-id}",
  ModelByCategory = "/device-category/{category-id}/models",
  DeviceModel = "/device-model",
  DeviceModelItem = "/device-model/{device-id}",
  UploadAsset = "/device/{device-id}/assets",
  User = "/user",
  DeviceAsset = "/device/{device-id}/assets",
  DeviceReport = "/device/{device-id}/reports",
  DeviceHistory = "/device/{device-id}/history",

  ContributorDevices = "/user/{user-id}/devices",
  ContributorHistory = "/user/{user-id}/history",
  ContributorReports = "/user/{user-id}/reports",
  ContributorAssign = "/user/{user-id}/assign-devices",
  ContributorUnassign = "/user/{user-id}/unassign-devices",
  ContributorResponsiveItem = "/user/{user-id}/responsive",
}

export interface Params<T> {
  offset: number;
  limit: number;
  sort: string | null;
  query?: T;
}

interface DeviceQueryParams {
  location: string | null;
  brand: string | null;
  category: string | null;
  assigned: string | null;
}

interface ContributorQueryParams {
  name: string | null;
  lastname: string | null;
  email: string | null;
  deleted: boolean;
}

export interface Payload<T> {
  count: number;
  rows: T[];
}

export interface Device {
  "assigned-to": undefined | DeviceAssignedTo;
  id: number;
  "serial-number": null | string;
  features: null | string;
  assigned: boolean;
  observations: null | string;
  "purchase-value": null | number;
  "purchase-date": null | Date;
  "created-at": Date;
  "updated-at": Date;
  model: Model;
  status: DeviceStatus;
  location: DeviceLocation;
}

export interface User {
  id: number;
  name: string;
  lastname: string;
  email: string;
  "created-at": Date;
  "updated-at": Date;
}

export interface DeviceBrand {
  id: number;
  name: string;
  "created-at"?: string;
  "updated-at"?: string;
}

interface DeviceAssignedTo {
  id: number;
  name: string;
}

export interface DeviceCategory {
  id: number;
  name: string;
  code?: string;
  "created-at"?: string;
  "updated-at"?: string;
}

export interface DeviceLocation {
  id: number;
  name: string;
  code?: string;
  "created-at"?: string;
  "updated-at"?: string;
}

export interface DeviceStatus {
  id: number;
  name: string;
  code?: string;
  "created-at"?: string;
  "updated-at"?: string;
}

export interface Model {
  id: number;
  name: string;
  brand: Pick<DeviceLocation, "id" | "name">;
  category: Pick<DeviceLocation, "id" | "name">;
}

interface PostDevice {
  length: number;
}

interface AssetUpload {
  status: string;
  name: string;
  size: number;
  percentage: number;
  uid: number;
  raw: {
    uid: number;
  };
}

export interface DataAsset {
  id: number;
  name: string;
  url: string;
}

interface DeviceAssets {
  images: DataAsset[];
  documents: DataAsset[];
}

export interface ReportDevice {
  id: number;
  "report-reason": string;
  details: string;
  answer: null;
  "created-at": string;
  "updated-at": string;
  "device-id": number;
  creator: DeviceAssignedTo;
  "assigned-to": DeviceAssignedTo;
  status: DeviceAssignedTo;
  category: DeviceAssignedTo;
}

export interface ContributorDevices {
  id: number;
  "serial-number": string;
  color: null | string;
  features: null | string;
  assigned: boolean;
  observations: null | string;
  "purchase-value": number | null;
  "purchase-date": null | string;
  "created-at": string;
  "updated-at": string;
  "deleted-at": null;
  model: Model;
  location: Location;
  status: Location;
}

export interface SingleContributor {
  id: number;
  name: string;
  lastname: string;
  email: string;
  "created-at": string;
  "updated-at": string;
  "deleted-at": null | string;
}

export interface HistoryContributor {
  id: number;
  details: string;
  "created-at": string;
  "updated-at": string;
  "deleted-at": null | string;
  "user-id": number;
  "history-action": { id: number; name: string };
  device: { DeviceModel: { name: string }; id: number };
}

export interface ReportContributor {
  id: number;
  "report-reason": string;
  details: string;
  answer: null;
  "created-at": string;
  "updated-at": string;
  creator: DeviceAssignedTo;
  "assigned-to": DeviceAssignedTo;
  device: DeviceContributor;
  status: DeviceAssignedTo;
  category: DeviceAssignedTo;
}

export interface DeviceContributor {
  id: number;
  model: Model;
}

export async function findDeviceLocation(params: Params<undefined>) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(Service.DeviceLocation, { params });
  return response.data.data as Payload<DeviceLocation>;
}

export async function createDeviceLocation(body: DeviceLocation) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(Service.DeviceLocation, body);
  return response.data.data;
}

export async function updateDeviceLocation(id: number, body: any) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceLocationItem.replace("{device-id}", id.toString()),
    body
  );
  return response.data.data;
}

export async function destroyDeviceLocation(id: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.delete(
    Service.DeviceLocationItem.replace("{device-id}", id.toString())
  );
  return response.data.data;
}

export async function findDeviceStatus(params?: Params<undefined>) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(Service.DeviceStatus, { params });
  return response.data.data as Payload<DeviceStatus>;
}

export async function createDeviceStatus(body: DeviceStatus) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(Service.DeviceStatus, body);
  return response.data.data;
}

export async function updateDeviceStatus(id: number, body: any) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceStatusItem.replace("{device-id}", id.toString()),
    body
  );
  return response.data.data;
}

export async function destroyDeviceStatus(id: number) {
  const client = RhovanionClient.getInstance();
  const params = { force: false };
  const response = await client.delete(
    Service.DeviceStatusItem.replace("{device-id}", id.toString()),
    { params }
  );
  return response.data.data;
}

export async function findDeviceBrand(params?: Params<undefined>) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(Service.DeviceBrand, { params });
  return response.data.data as DeviceBrand[];
}

export async function createDeviceBrand(body: DeviceBrand) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(Service.DeviceBrand, body);
  return response.data.data;
}
export async function updateDeviceBrand(id: number, body: any) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceBrandItem.replace("{device-id}", id.toString()),
    body
  );
  return response.data.data;
}

export async function destroyDeviceBrand(id: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.delete(
    Service.DeviceBrandItem.replace("{device-id}", id.toString())
  );
  return response.data.data;
}

export async function findDeviceCategory(params?: Params<undefined>) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(Service.DeviceCategory, { params });
  return response.data.data as Payload<DeviceCategory>;
}

export async function createDeviceCategory(body: DeviceCategory) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(Service.DeviceCategory, body);
  return response.data.data;
}

export async function destroyDeviceCategory(id: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.delete(
    Service.DeviceCategoryItem.replace("{device-id}", id.toString())
  );
  return response.data.data;
}

export async function updateDeviceCategory(id: number, body: any) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceCategoryItem.replace("{device-id}", id.toString()),
    body
  );
  return response.data.data;
}

export async function createDeviceModel(body: Model) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(Service.DeviceModel, body);
  return response.data.data;
}

export async function destroyDeviceModel(id: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.delete(
    Service.DeviceModelItem.replace("{device-id}", id.toString())
  );
  return response.data.data;
}

export async function updateDeviceModel(id: number, body: any) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceModelItem.replace("{device-id}", id.toString()),
    body
  );
  return response.data.data;
}

export async function findDevice(params: Params<DeviceQueryParams>) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(Service.Device, { params });
  return response.data.data as Payload<Device>;
}

// <--------------- USERS ----------------->
export async function findContributor(params: Params<ContributorQueryParams>) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(Service.Contributor, { params });
  return response.data.data as Payload<Contributor>;
}

export async function createContributor(body: Contributor) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(Service.Contributor, body);
  return response.data.data;
}

export async function updateContributor(id: number, body: Contributor) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.ContributorItem.replace("{user-id}", id.toString()),
    body
  );
  return response.data.data;
}

export async function destroyContributor(id: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.delete(
    Service.ContributorItem.replace("{user-id}", id.toString())
  );
  return response.data.data;
}

export async function createDevice(body: DeviceClass.default) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(Service.Device, {
    devices: [{ ...body }],
  });
  return response.data.data;
}

export async function updateDevice(id: number, body: DeviceClass.default) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceItem.replace("{device-id}", id.toString()),
    body
  );
  return response.data.data;
}

export async function destroyDevice(id: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.delete(
    Service.DeviceItem.replace("{device-id}", id.toString())
  );
  return response.data.data;
}

export async function findUsers(params: Params<undefined>) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(Service.User, { params });
  return response.data.data as Payload<User>;
}

export async function assignDevice(idDevice: number, idUser: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceAssign.replace("{device-id}", idDevice.toString()),
    { "user-id": idUser }
  );
  return response.data.data;
}

export async function unassignDevice(idDevice: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.patch(
    Service.DeviceUnassign.replace("{device-id}", idDevice.toString())
  );
  return response.data.data;
}

export async function findModels(id: number) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.ModelByCategory.replace("{category-id}", id.toString())
  );
  return response.data.data as Payload<Model>;
}

export async function uploadAsset(id: number, assets: FormData) {
  const client = RhovanionClient.getInstance();
  const response = await client.post(
    Service.UploadAsset.replace("{device-id}", id.toString()),
    assets
  );
  return response;
}

export async function findAssets(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.DeviceAsset.replace("{device-id}", id)
  );
  return response.data.data as DeviceAssets;
}

export async function findOneDevice(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.DeviceItem.replace("{device-id}", id)
  );
  return response.data.data as Device;
}

export async function findDeviceReports(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.DeviceReport.replace("{device-id}", id)
  );
  return response.data.data as Payload<ReportDevice>;
}

export async function findDeviceHistory(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.DeviceHistory.replace("{device-id}", id)
  );
  return response.data.data;
}

export async function findContributorDevices(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.ContributorDevices.replace("{user-id}", id)
  );

  return response.data.data;
}

export async function findOneContributor(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.ContributorItem.replace("{user-id}", id)
  );

  return response.data.data;
}

export async function findContributorHistory(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.ContributorHistory.replace("{user-id}", id)
  );

  return response.data.data;
}

export async function findContributorReports(id: string, rol: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.ContributorReports.replace("{user-id}", id),
    {
      params: {
        rol,
      },
    }
  );

  return response.data.data;
}

export async function multipleAssign(id: string, devices: number[]) {
  const client = RhovanionClient.getInstance();
  await client.post(Service.ContributorAssign.replace("{user-id}", id), {
    devices,
  });
}

export async function multipleUnassign(id: string, devices: number[]) {
  const client = RhovanionClient.getInstance();
  await client.post(Service.ContributorUnassign.replace("{user-id}", id), {
    devices,
  });
}

export async function findContributorResposives(id: string) {
  const client = RhovanionClient.getInstance();
  const response = await client.find(
    Service.ContributorResponsiveItem.replace("{user-id}", id)
  );

  return response.data.data;
}

export async function uploadResponsive(id: string, responsive: FormData) {
  const client = RhovanionClient.getInstance();
  await client.post(
    Service.ContributorResponsiveItem.replace("{user-id}", id),
    responsive
  );
}
