
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import * as ColumnServices from "@/api/helpers/Column";
import Column from "@/models/Column";
import Notify from "@/utils/notifications";
import _ from "underscore";

@Component
export default class ColumnForm extends Vue {
  @Prop() column!: Column;
  @Prop() create!: boolean;
  @Prop() systems!: Array<object>;
  @Prop() show!: boolean;

  loading = false;
  dataTypes = [
    {
      label: "Fecha",
      value: "date",
    },
    {
      label: "Id",
      value: "id",
    },
    {
      label: "Lógico",
      value: "boolean",
    },
    {
      label: "Número",
      value: "number",
    },
    {
      label: "Texto",
      value: "string",
    },
  ];
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    code: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    dataType: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };
  selectedSystems: Array<number> = [];

  createColumn() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid || this.selectedSystems.length == 0) {
        this.loading = false;
        return false;
      } else {
        this.column.systems = this.selectedSystems;
        ColumnServices.create(this.column)
          .then(() => {
            this.$emit("refreshColumn");
            Notify.successful("Columna creada exitosamente.");
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDrawer();
          });
      }
    });
  }

  updateColumn() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid || this.selectedSystems.length == 0) {
        this.loading = false;
        return false;
      } else {
        if (this.column && this.column.id) {
          this.column.systems = this.selectedSystems;
          ColumnServices.update(this.column.id, this.column)
            .then(() => {
              this.$emit("refreshColumn");
              Notify.successful("Columna actualizada exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.closeDrawer();
            });
        }
      }
    });
  }

  closeDrawer() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", false);
  }

  @Watch("column")
  changedColumn() {
    if (this.column.systems && this.column.systems.length) {
      this.selectedSystems = _.pluck(this.column.systems, "systemId");
    } else {
      this.selectedSystems = [];
    }
  }

  get title() {
    return this.create ? "Crear nueva columna" : "Actualizar columna";
  }

  get showDrawer() {
    return this.show;
  }
}
