
import { Component, Mixins, Prop, Watch, Vue } from "vue-property-decorator";
import { Template } from "@/api/tharbad";
import { patchTemplate } from "@/api/tharbad";
import Notify from "@/utils/notifications";
import AddTemplateDrawer from "./AddTemplateDrawer.vue";

@Component
export default class EditTemplateDrawer extends Mixins(Vue, AddTemplateDrawer) {
  @Prop({ default: null }) item!: Template | null;

  closeDrawerEdit() {
    if (this.item !== null) {
      Object.assign(this.item, this.itemData);
    }
    this.closeDrawer();
  }

  processItem() {
    this.validateForm()
      .then(async isValid => {
        if (this.item !== null && isValid) {
          try {
            this.fullscreenLoading = true;
            this.loading = true;
            await patchTemplate(this.item.id, this.item);
            Notify.successful("Plantilla actualizada exitosamente.");
            this.$emit("updateView");
            this.$emit("closeDrawer", false);
          } catch (e) {
            if (e.data === undefined) {
              Notify.error((e as Error).message);
            } else {
              Notify.gebServerError(e as Error);
            }
          }
        }
      })
      .finally(() => {
        this.fullscreenLoading = false;
        this.loading = false;
      });
  }

  @Watch("show")
  copyItemValue() {
    if (this.show) {
      Object.assign(this.itemData, this.item);
    }
  }
}
