
import { Vue, Component, Prop } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import * as ColumnService from "@/api/helpers/Column";
import OfferType from "@/models/OfferType";
import Column from "@/models/Column";
import { ElForm } from "element-ui/types/form";
import Notify from "@/utils/notifications";

interface FormRule {
  required?: boolean;
  type?: string;
  min?: number;
  max?: number;
  validator?: (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => void;
  trigger?: string;
  pattern?: RegExp;
  message: string;
}

interface TextAreaSize {
  minRows: number;
  maxRows: number;
}

@Component({
  components: {
    DeletePopover,
  },
})
export default class OfferForm extends Vue {
  @Prop({ required: true }) offer!: OfferType;

  textAreaSize: TextAreaSize = { minRows: 6, maxRows: Infinity };
  columns: ColumnService.Column[] = [];

  rules: { [key: string]: FormRule[] } = {
    name: [
      {
        required: true,
        message: "Este campo es requerido",
        trigger: "blur",
      },
    ],
    customParams: [
      {
        required: false,
        message: "El formato JSON es inválido",
        trigger: "blur",
        validator(rule, value, callback) {
          try {
            if (value !== undefined) {
              JSON.parse(value);
            }
            callback();
          } catch (error) {
            // If parsing fails, the JSON syntax is invalid.
            callback(rule.message);
          }
        },
      },
    ],
  };

  isColumnSelected(id: number): boolean {
    return this.offer.columns.some(column => column.id === id);
  }

  isColumnCreated(index: number): boolean {
    return "offerTypeColumnId" in this.offer.columns[index];
  }

  addColumn() {
    this.offer.columns.push(new Column());
  }

  deleteColumn(value: number) {
    const option = this.offer.columns.find(
      column => column.offerTypeColumnId === value
    );
    if (option) {
      this.offer.columns = this.offer.columns.filter(
        column => column.offerTypeColumnId !== option.offerTypeColumnId
      );
      this.$emit("deleteColumn", option.offerTypeColumnId);
    } else {
      this.offer.columns.splice(value, 1);
    }

    if (this.offer.columns.length === 0) {
      this.addColumn();
    }
  }

  findColumns() {
    ColumnService.find()
      .then(res => {
        this.columns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  validateForm(): Promise<boolean> {
    return (this.$refs.offerForm as ElForm).validate();
  }

  created() {
    this.findColumns();
  }
}
