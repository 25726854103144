import System from "@/models/System";

export default class Card {
  id?: number;
  name!: string;
  systems: Array<System> = [];
  systemId!: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CatalogItem<T> {
  id: T;
  description: string;
}
