import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Interceptor from "./helpers/Interceptor";

Vue.use(VueAxios, axios);

export interface Response<T> {
  code: string;
  message: string;
  data: T;
}

export interface Count<T> {
  count: number;
  rows: T[];
}

export interface Params {
  limit?: number;
  offset?: number;
  order?: string;
}

export const enum Service {
  LOGIN = "/token",
  REFRESH = "/token",
  LOGOUT = "/token",
  ME = "/token/me",
}

export const enum Tokens {
  ACCESS = "access_token",
  REFRESH = "refresh_token",
}

export const enum TokenType {
  Default = "token_type",
}

export const enum Header {
  AUTHORIZATION = "Authorization",
}

const enum UserData {
  ME = "me",
}

export interface PasswordCredentials {
  username: string;
  password: string;
  grantType?: string;
  clientId?: string;
}

interface RefreshCredentials {
  refreshToken: string;
  clientSecret?: string;
  grantType?: string;
  clientId?: string;
}

const Uri: string = process.env.VUE_APP_BACK || "";
const interceptor = -1;

const passwordCredentials = ({
  username,
  password,
  grantType = "password",
  clientId = process.env.VUE_APP_CLIENT_ID,
}: PasswordCredentials) => ({
  // eslint-disable-next-line @typescript-eslint/camelcase
  client_id: clientId,
  username: username,
  // eslint-disable-next-line @typescript-eslint/camelcase
  grant_type: grantType,
  password: password,
});

const refreshCredentials = ({
  refreshToken,
  clientSecret = process.env.VUE_APP_CLIENT_SECRET,
  grantType = "refresh_token",
  clientId = process.env.VUE_APP_CLIENT_ID,
}: RefreshCredentials) => ({
  // eslint-disable-next-line @typescript-eslint/camelcase
  client_id: clientId,
  // eslint-disable-next-line @typescript-eslint/camelcase
  client_secret: clientSecret,
  // eslint-disable-next-line @typescript-eslint/camelcase
  grant_type: grantType,
  // eslint-disable-next-line @typescript-eslint/camelcase
  refresh_token: refreshToken,
});

export class Gondor {
  static API = axios.create({ baseURL: Uri });

  static HEADERS = {
    authorization: () => {
      return Gondor.API.defaults.headers.common[Header.AUTHORIZATION];
    },
  };

  static SESSION = {
    isAuthenticated: () => {
      const token = localStorage.getItem(Tokens.ACCESS);
      const tokenType = localStorage.getItem(TokenType.Default);
      const refresh = localStorage.getItem(Tokens.REFRESH);
      if (!!token && !!tokenType && !!refresh) {
        Gondor.API.defaults.headers.common[
          Header.AUTHORIZATION
        ] = `${tokenType} ${token}`;
        Interceptor.add(Gondor.API, Gondor, Service.REFRESH);
        return true;
      }
      localStorage.clear();
      sessionStorage.clear();
      return false;
    },
  };

  static async login(body: PasswordCredentials) {
    try {
      const resp = await this.API.post(
        Service.LOGIN,
        passwordCredentials(body)
      );
      const data = resp.data.data;
      localStorage.setItem(Tokens.ACCESS, data[Tokens.ACCESS]);
      localStorage.setItem(Tokens.REFRESH, data[Tokens.REFRESH]);
      localStorage.setItem(TokenType.Default, data[TokenType.Default]);
      this.API.defaults.headers.common[Header.AUTHORIZATION] = `${
        data[TokenType.Default]
      } ${data[Tokens.ACCESS]}`;
      Interceptor.add(this.API, this, Service.REFRESH);
    } catch (err) {
      Gondor.destroySession();
      throw err;
    }
  }

  static async refresh() {
    if (!this.SESSION.isAuthenticated()) {
      throw new Error("err_missing_tokens");
    }
    try {
      const body = refreshCredentials({
        refreshToken: localStorage.getItem(Tokens.REFRESH) || "",
      });
      delete this.API.defaults.headers.common[Header.AUTHORIZATION];
      const resp = await this.API.post(Service.LOGIN, body);
      const data = resp.data.data;
      localStorage.setItem(Tokens.ACCESS, data[Tokens.ACCESS]);
      localStorage.setItem(Tokens.REFRESH, data[Tokens.REFRESH]);
      localStorage.setItem(TokenType.Default, data[TokenType.Default]);
      this.API.defaults.headers.common[Header.AUTHORIZATION] = `${
        data[TokenType.Default]
      } ${data[Tokens.ACCESS]}`;
    } catch (err) {
      Gondor.destroySession();
      throw err;
    }
  }

  static async me() {
    if (!this.SESSION.isAuthenticated()) {
      throw new Error("err_missing_tokens");
    }
    try {
      const user = localStorage.getItem(UserData.ME);
      if (!user) {
        const resp = await this.API.get(Service.ME);
        localStorage.setItem(UserData.ME, JSON.stringify(resp.data.data));
        return resp;
      }
    } catch (err) {
      Gondor.destroySession();
      throw err;
    }
  }

  static destroySession() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      this.API.interceptors.response.eject(interceptor);
      delete this.API.defaults.headers.common[Header.AUTHORIZATION];
    } catch (err) {
      console.error(err);
    }
  }

  static async logout() {
    await this.API.delete(Service.LOGOUT);
    Gondor.destroySession();
  }
}
