
import { Component, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import CardRuleDrawer from "@/components/Liink/CardRuleDrawer.vue";
import CardRuleCard from "@/components/Liink/CardRuleCard.vue";
import Liink from "@/api/Liink";
import CardRule from "@/models/Liink/CardRule";
import Lender from "@/models/Liink/Lender";
import Catalog from "@/models/Liink/Catalog";

@Component({
  components: {
    CardRuleCard,
    CardRuleDrawer,
  },
})
export default class CatalogView extends Vue {
  loading = false;
  loadingDrawer = false;
  rule: CardRule = {} as CardRule;
  rules: Array<CardRule> = [];
  lenders: Array<Lender> = [];
  employmentStatus: Array<Catalog> = [];
  employmentTimeWorked: Array<Catalog> = [];
  cardTypes: Array<Catalog> = [];
  drawer = false;
  create = false;

  async createRule(data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.createCardRule(data);

      Notify.successful("La regla se creó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async updateRule(id: number, data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.updateCardRule(id, data);

      Notify.successful("La regla se actualizó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async deleteRule(id: number) {
    try {
      await Liink.deleteCardRule(id);

      Notify.successful("La regla se eliminó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.load();
  }

  openDrawer(rule?: CardRule) {
    this.create = rule == undefined;
    this.rule = rule
      ? rule
      : ({ isActive: true, proofOfIncome: false } as CardRule);
    this.drawer = true;
  }

  closeDrawer() {
    this.loadingDrawer = false;
    this.drawer = false;
    this.rule = {} as CardRule;
  }

  async load() {
    this.loading = true;

    try {
      this.lenders = await Liink.getLenders();
    } catch (err) {
      console.error(err);
    }

    try {
      this.employmentStatus = await Liink.getCatalog("employment-status");
    } catch (err) {
      console.error(err);
    }

    try {
      this.employmentTimeWorked = await Liink.getCatalog(
        "employment-time-worked"
      );
    } catch (err) {
      console.error(err);
    }

    try {
      this.cardTypes = await Liink.getCatalog("card-types");
    } catch (err) {
      console.error(err);
    }

    try {
      this.rules = await Liink.getCardRules();
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  created() {
    const title = "Reglas de tarjetas";
    const index = "5.5";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Liink",
        to: "/liink",
      },
      {
        text: title,
      },
    ]);

    this.load();
  }
}
