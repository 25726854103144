
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import Catalog from "@/models/Catalog";
import _ from "underscore";

@Component
export default class CatalogDrawer extends Vue {
  @Prop() catalog!: Catalog;
  @Prop() create!: boolean;
  @Prop() show!: boolean;
  @Prop() loading!: boolean;
  @Prop() title!: string;
  @Prop() type!: string;
  @Prop() systems!: Array<object>;
  @Prop() hasSystems!: boolean;

  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
  };
  selectedSystems: Array<number | undefined> = [];

  createCatalog() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (this.hasSystems) {
        if (!valid || this.selectedSystems.length == 0) {
          return false;
        } else {
          this.$emit(
            "create",
            this.catalog.name,
            this.selectedSystems,
            this.type
          );
        }
      } else {
        if (!valid) {
          return false;
        } else {
          this.$emit("create", this.catalog.name, null, this.type);
        }
      }
    });
  }

  updateCatalog() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (this.hasSystems) {
        if (!valid || this.selectedSystems.length == 0) {
          return false;
        } else {
          this.$emit(
            "update",
            this.catalog.id,
            this.catalog.name,
            this.selectedSystems,
            this.type
          );
        }
      } else {
        if (!valid) {
          return false;
        } else {
          this.$emit(
            "update",
            this.catalog.id,
            this.catalog.name,
            null,
            this.type
          );
        }
      }
    });
  }

  closeDrawer(cancel?: boolean) {
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.selectedSystems = [];
    this.$emit("closeDrawer", cancel);
  }

  @Watch("catalog")
  changedCatalog() {
    if (this.hasSystems && this.catalog.systems.length) {
      this.selectedSystems = _.pluck(this.catalog.systems, "systemId");
    }
  }

  get showDrawer() {
    return this.show;
  }
}
