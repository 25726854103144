import { Gondor } from "../Gondor";
import { FaqItem } from "@/models/Faq";

const ACTIONS = {
  FAQ: "/faq/",
  FAQ_ITEM: "/faq/{id}/",
  FAQ_ORDER: "/faq/order/",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.FAQ, { params });
}

export async function findOne(id: number | string) {
  id = id.toString();
  const response = await Gondor.API.get(ACTIONS.FAQ_ITEM.replace("{id}", id));
  return response.data.data as FaqItem;
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.FAQ, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.FAQ_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.FAQ_ITEM.replace("{id}", id));
}

export function order(body: object) {
  return Gondor.API.patch(ACTIONS.FAQ_ORDER, body);
}
