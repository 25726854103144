
import { Component, Prop, Vue } from "vue-property-decorator";
import { JourneyItem } from "@/models/Journey";
import JourneyForm from "./JourneyForm.vue";
import { CatalogItem } from "@/models/Catalog";
import { postJourney, JourneyPostItem } from "@/api/tharbad";
import Notify from "@/utils/notifications";

@Component({
  components: { JourneyForm },
})
export default class AddJourneyDrawer extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: "Nuevo recorrido" }) title!: string;
  @Prop({ default: [] }) countries!: CatalogItem<string>[];

  fullscreenLoading = false;
  loading = false;
  itemData = {
    "schedule-time": null,
    active: true,
    "shipment-max-size": 0,
    "country-code": "",
    steps: ([] as unknown) as JourneyItem["steps"],
  } as JourneyItem;

  get isVisible() {
    return this.show;
  }

  private getForm(subFormRefName: string) {
    return (this.$refs["form"] as HTMLFormElement).$refs[subFormRefName];
  }

  protected toggleLoading(flag: boolean) {
    this.fullscreenLoading = flag;
    this.loading = flag;
  }

  closeDrawer() {
    this.toggleLoading(false);
    for (const name of ["form", "stepsForm"]) {
      const form = this.getForm(name);
      form.resetFields();
      form.clearValidate();
    }
    this.itemData.steps = [];
    this.$emit("closeDrawer", false);
  }

  async validateForm() {
    const form = this.getForm("form");
    const stepsForm = this.getForm("stepsForm");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      form.validate((valid: boolean) => {
        if (!valid) {
          resolve(valid);
        } else {
          stepsForm.validate((valid2: boolean) => {
            resolve(valid2);
          });
        }
      });
    });
  }

  processItem() {
    this.validateForm()
      .then(async isValid => {
        if (this.itemData !== null && isValid) {
          try {
            this.toggleLoading(true);
            await postJourney(this.itemData as JourneyPostItem);
            Notify.successful("Recorrido creado exitosamente.");
            this.$emit("updateView");
            this.closeDrawer();
          } catch (e) {
            if (e.data === undefined) {
              Notify.error((e as Error).message);
            } else {
              Notify.gebServerError(e as Error);
            }
          }
        }
      })
      .finally(() => {
        this.toggleLoading(false);
      });
  }
}
