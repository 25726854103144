import { Gondor, Response, Count } from "../Gondor";
import { KeySystem, KeySystemSchema } from "./KeySystem";
import { OfferType } from "./OfferType";

export interface System {
  id: number;
  name: string;
  url: string;
  keyId: string;
  active: boolean;
  showImprovement: boolean;
  createdAt: Date;
  updatedAt: Date;
  countryId: number;
}

export interface SystemOffer extends OfferType {
  columns: any[];
  offerTypeSystemId: number;
}

interface Ranking {
  veryUnsatisfied: number;
  unsatisfied: number;
  satisfied: number;
  verySatisfied: number;
  ranking: number;
}

const ACTIONS = {
  SYSTEM: "/system/",
  SYSTEM_ITEM: "/system/{id}/",
  SYSTEM_SECRET: "/system/{id}/new-secret",
  SYSTEM_RANKING: "/system/{id}/ranking",
  SYSTEM_OFFERT: "/system/{id}/offerType",
  SYSTEM_KEY: "/system/{id}/key",
  SYSTEM_KEY_ITEM: "/system/{systemId}/key/{keyId}",
};

export function find(params?: object) {
  return Gondor.API.get<Response<System[]>>(ACTIONS.SYSTEM, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.SYSTEM_ITEM.replace("{id}", id));
}

export function create(body: any) {
  return Gondor.API.post(ACTIONS.SYSTEM, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.SYSTEM_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.SYSTEM_ITEM.replace("{id}", id));
}

export function newSecret(id: number | string) {
  id = id.toString();
  return Gondor.API.post(ACTIONS.SYSTEM_SECRET.replace("{id}", id));
}

export function ranking(id: number | string) {
  id = id.toString();
  return Gondor.API.get<Response<Ranking>>(
    ACTIONS.SYSTEM_RANKING.replace("{id}", id)
  );
}

export function findKeys(id: number | string, params?: object) {
  id = id.toString();
  return Gondor.API.get<Response<Count<KeySystem>>>(
    ACTIONS.SYSTEM_KEY.replace("{id}", id),
    { params }
  );
}

export const offerType = (id: number, params?: object) => {
  return Gondor.API.get<Response<Count<SystemOffer>>>(
    ACTIONS.SYSTEM_OFFERT.replace("{id}", String(id)),
    { params }
  );
};

export const keySystem = (id: number) => {
  return Gondor.API.get<Response<Count<KeySystem>>>(
    ACTIONS.SYSTEM_KEY.replace("{id}", String(id))
  );
};

export const createKeySystem = (
  systemId: number,
  body: Omit<KeySystemSchema, "systemId">
) => {
  return Gondor.API.post(
    ACTIONS.SYSTEM_KEY.replace("{id}", String(systemId)),
    body
  );
};

export const updateKeySystem = (
  systemId: number,
  keyId: number,
  body: Partial<KeySystemSchema>
) => {
  return Gondor.API.patch(
    ACTIONS.SYSTEM_KEY_ITEM.replace("{systemId}", String(systemId)).replace(
      "{keyId}",
      String(keyId)
    ),
    body
  );
};

export const deleteKeySystem = (systemId: number, keyId: number) => {
  return Gondor.API.delete(
    ACTIONS.SYSTEM_KEY_ITEM.replace("{systemId}", String(systemId)).replace(
      "{keyId}",
      String(keyId)
    )
  );
};
