import axios, { AxiosInstance } from "axios";
import Lead from "@/models/Liink/Lead";
import CardRule from "@/models/Liink/CardRule";
import DebtRule from "@/models/Liink/DebtRule";
import LoanRule from "@/models/Liink/LoanRule";
import Lender from "@/models/Liink/Lender";
import FinancialInstitution from "@/models/Liink/FinancialInstitution";
import Catalog from "@/models/Liink/Catalog";

export interface LiinkResponse<T> {
  count: number;

  rows: T[];
}

export interface GetLeadsParams {
  take: number;

  skip: number;

  name?: string;

  email?: string;

  phone?: string;

  birthdate?: string;

  gender?: string;

  CLABE?: string;

  bankId?: string;

  zipcode?: string;

  proofOfIncome?: string;

  monthlyIncome?: string;

  monthlyExpenses?: string;

  statusId?: string;

  timeWorkedId?: string;

  propertyOwner?: string;

  score?: string;

  loan?: string;

  debt?: string;

  institutionId?: string;

  card?: string;

  offers?: string;

  createdAt?: string;
}

export class LiinkClient {
  private readonly http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_LIINK_API,
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_LIINK_TOKEN}`,
      },
    });
  }

  async getLeads(params: GetLeadsParams) {
    const response = await this.http.get<LiinkResponse<Lead>>("/leads", {
      params,
    });

    return response.data;
  }

  async downloadLeads(params: GetLeadsParams) {
    const response = await this.http.get<Blob>("/leads", {
      params,
      headers: {
        Accept: "text/csv",
      },
      responseType: "blob",
    });

    return response.data;
  }

  async getCardRules() {
    const response = await this.http.get<CardRule[]>("/card-rules");

    return response.data;
  }

  async createCardRule(data: unknown) {
    await this.http.post("/card-rules", data);
  }

  async updateCardRule(id: number, data: unknown) {
    await this.http.put(`/card-rules/${id}`, data);
  }

  async deleteCardRule(id: number) {
    await this.http.delete(`/card-rules/${id}`);
  }

  async getDebtRules() {
    const response = await this.http.get<DebtRule[]>("/debt-rules");

    return response.data;
  }

  async createDebtRule(data: unknown) {
    await this.http.post("/debt-rules", data);
  }

  async updateDebtRule(id: number, data: unknown) {
    await this.http.put(`/debt-rules/${id}`, data);
  }

  async deleteDebtRule(id: number) {
    await this.http.delete(`/debt-rules/${id}`);
  }

  async getLoanRules() {
    const response = await this.http.get<LoanRule[]>("/loan-rules");

    return response.data;
  }

  async createLoanRule(data: unknown) {
    await this.http.post("/loan-rules", data);
  }

  async updateLoanRule(id: number, data: unknown) {
    await this.http.put(`/loan-rules/${id}`, data);
  }

  async deleteLoanRule(id: number) {
    await this.http.delete(`/loan-rules/${id}`);
  }

  async getLenders() {
    const response = await this.http.get<Lender[]>("/lenders");

    return response.data;
  }

  async createLender(data: unknown) {
    await this.http.post("/lenders", data);
  }

  async updateLender(id: number, data: unknown) {
    await this.http.put(`/lenders/${id}`, data);
  }

  async deleteLender(id: number) {
    await this.http.delete(`/lenders/${id}`);
  }

  async getFinancialInstitution() {
    const response = await this.http.get<FinancialInstitution[]>(
      "/financial-institutions"
    );

    return response.data;
  }

  async createFinancialInstitution(data: unknown) {
    await this.http.post("/financial-institutions", data);
  }

  async updateFinancialInstitution(id: number, data: unknown) {
    await this.http.put(`/financial-institutions/${id}`, data);
  }

  async deleteFinancialInstitution(id: number) {
    await this.http.delete(`/financial-institutions/${id}`);
  }

  async getCatalog(name: string) {
    const response = await this.http.get<Catalog[]>(`/${name}`);

    return response.data;
  }

  async createCatalog(name: string, data: unknown) {
    await this.http.post(`/${name}`, data);
  }

  async updateCatalog(name: string, id: number, data: unknown) {
    await this.http.put(`/${name}/${id}`, data);
  }

  async deleteCatalog(name: string, id: number) {
    await this.http.delete(`/${name}/${id}`);
  }
}

const client = new LiinkClient();

export default client;
