
import { Component, Vue } from "vue-property-decorator";
import ContributorDrawer from "@/components/inventory/TheContributorDrawer.vue";
import * as rhovanion from "@/api/rhovanion";
import _ from "underscore";
import Contributor from "../../models/Contributor";
import DeletePopover from "@/components/DeletePopover.vue";
import Notify from "@/utils/notifications";

interface SortParams {
  column: Record<string, unknown>;
  order: string | null;
  prop: string;
}

interface Query {
  name: string | null;
  lastname: string | null;
  email: string | null;
  deleted: boolean;
}

@Component({
  components: {
    DeletePopover,
    ContributorDrawer,
  },
})
export default class InventoryContributors extends Vue {
  readonly limitArray = [10, 25, 50, 100, 250, 500, 1000];
  readonly defaultParams: rhovanion.Params<Query> = {
    query: {
      name: null,
      lastname: null,
      email: null,
      deleted: false,
    },
    limit: 10,
    offset: 0,
    sort: "-created-at",
  };
  create = false;
  contributorsDrawer = false;
  contributor = new Contributor();
  contributorLoading = true;
  params = _.clone(this.defaultParams);
  currentPage = 1;
  contributors = {} as rhovanion.Payload<Contributor>;

  getFilteredContributors() {
    this.contributorLoading = true;
    rhovanion
      .findContributor({
        ...this.params.query,
        limit: this.params.limit,
        offset: this.params.offset,
        sort: this.params.sort,
      })
      .then(res => {
        this.contributors = res;
      })
      .finally(() => (this.contributorLoading = false));
  }

  getContributors(/*resetParams = false, */ page = this.currentPage) {
    // if (resetParams) {
    //   this.params = _.clone(this.defaultParams);
    // }
    this.contributorLoading = true;
    this.currentPage = page;
    this.params.offset = (this.currentPage - 1) * this.params.limit;
    rhovanion
      .findContributor({
        ...this.params.query,
        limit: this.params.limit,
        offset: this.params.offset,
        sort: this.params.sort,
      })
      .then(res => {
        this.contributors = res;
      })
      .finally(() => (this.contributorLoading = false));
  }

  newDrawer() {
    this.create = true;
    this.contributor = new Contributor();
    this.contributorsDrawer = true;
  }

  editDrawer(index: number) {
    this.create = false;
    this.contributor = { ...this.contributors.rows[index] };
    this.contributorsDrawer = true;
  }

  deleteContributor(id: number) {
    this.contributorLoading = true;
    rhovanion
      .destroyContributor(id)
      .then(res => {
        this.getContributors();
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.contributorLoading = false;
      });
  }

  closeDrawer(value: boolean) {
    this.contributorsDrawer = value;
  }

  sortTable(params: SortParams) {
    if (params.order === null) {
      this.params.sort = this.defaultParams.sort;
    } else {
      this.params.sort = `${params.order === "descending" ? "-" : "+"}${
        params.prop
      }`;
    }
    this.getContributors();
  }

  mounted() {
    this.getContributors();
  }

  created() {
    const index = "7.2";
    const title = "Colaboradores";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Inventario",
        to: {
          name: "inventory-devices",
        },
      },
      {
        text: "Colaboradores",
      },
    ]);
  }

  redirectDetails(id: any) {
    this.$router.push({
      name: "inventory-contributors-details",
      params: { id: id },
    });
  }

  get areArchived() {
    if ("rows" in this.contributors) {
      return this.contributors.rows.some(item => item["deleted-at"] !== null);
    }

    return false;
  }
}
