import { Gondor } from "../Gondor";

const ACTIONS = {
  MOTIVATION: "/motivation/",
  MOTIVATION_ITEM: "/motivation/{id}/",
  ADD_SYSTEMS: "/motivation/add/system",
  REMOVE_SYSTEMS: "/motivation/remove/system",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.MOTIVATION, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.MOTIVATION_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.MOTIVATION, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.MOTIVATION_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.MOTIVATION_ITEM.replace("{id}", id));
}

export function addSystems(body: object) {
  return Gondor.API.post(ACTIONS.ADD_SYSTEMS, body);
}

export function removeSystems(body: object) {
  return Gondor.API.post(ACTIONS.REMOVE_SYSTEMS, body);
}
