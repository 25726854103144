import { Gondor } from "../Gondor";

const enum Service {
  LEAD = "/lead/",
  LEAD_ITEM = "/lead/{id}/",
  LEAD_COUNT_BY_SYSTEM = "/lead/system/count",
  LEAD_BATCH_CREATE = "/leads/batch/{roomId}",
}

export interface BaseQuery {
  start?: string;
  end?: string;
  system?: Array<number>;
  completed?: boolean;
  identicalPhones?: object;
  identicalEmails?: object;
}

export interface CountBySystemQuery extends BaseQuery {
  dateFormat?: string;
}

export interface Query extends BaseQuery {
  columns: string;
  state?: Array<number>;
}

export function find(params?: object) {
  return Gondor.API.get(Service.LEAD, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(Service.LEAD_ITEM.replace("{id}", id));
}

export function countBySystem(params?: CountBySystemQuery) {
  return Gondor.API.get(Service.LEAD_COUNT_BY_SYSTEM, { params });
}

export function create(body: object) {
  return Gondor.API.post(Service.LEAD, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(Service.LEAD_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(Service.LEAD_ITEM.replace("{id}", id));
}

export function batchCreate(roomId: string, body: object) {
  return Gondor.API.post(
    Service.LEAD_BATCH_CREATE.replace("{roomId}", roomId),
    body
  );
}
