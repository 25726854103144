import axios, { AxiosInstance } from "axios";
import router from "../../router/";

const enum Header {
  AUTHORIZATION = "Authorization",
}

type API = {
  destroySession: () => void;
  refresh: () => Promise<void>;
  HEADERS: { authorization: () => string };
  SESSION: { isAuthenticated: () => boolean };
};

const Interceptor = {
  add: (axio: AxiosInstance, api: API, refreshUri: string) => {
    axio.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        if (!err.response || err.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(err);
          });
        }
        const config = err.config;
        if (config.url === refreshUri && config.method === "post") {
          if (api.SESSION.isAuthenticated()) {
            api.destroySession();
            router.push({ path: "/login" });
          }
          return Promise.reject(err);
        }
        return api
          .refresh()
          .then(() => {
            config.headers[Header.AUTHORIZATION] = api.HEADERS.authorization();
            return axios
              .request(config)
              .then(response => {
                return response;
              })
              .catch(err => {
                return err;
              });
          })
          .catch(err => {
            if (api.SESSION.isAuthenticated()) {
              api.destroySession();
              router.push({ path: "/login" });
            }
            return err;
          });
      }
    );
  },
};

export default Interceptor;
