
import { Component, Ref, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import Card from "@/models/Card";
import * as CardServices from "@/api/helpers/Card";
import CardForm from "@/components/CardForm.vue";
import RuleForm from "@/components/RuleForm.vue";
import CardTemplate from "@/components/CardTemplate.vue";
import ConfigForm from "@/components/CardConfigForm.vue";
import * as CardSettingServices from "@/api/helpers/CardSetting";
import * as ColumnServices from "@/api/helpers/Column";
import * as SystemServices from "@/api/helpers/System";
import Column from "@/models/Column";
import _ from "underscore";
import { Count } from "@/api/Gondor";
import { KeySystem } from "@/api/helpers/KeySystem";
import TheExampleCard from "@/components/TheExampleCard.vue";
import Rule from "@/models/Rule";

interface CardSetting {
  id: number;
  value: string;
  cardId: number;
  keySystemId: number;
  name: string; //KeySystem name
}

@Component({
  components: {
    CardForm,
    RuleForm,
    CardTemplate,
    ConfigForm,
    TheExampleCard,
  },
})
export default class CardWizard extends Vue {
  @Ref() readonly cardForm!: CardForm;

  systemId = +this.$route.params.system || +sessionStorage.getItem("systemId")!;
  fullscreenLoading = false;
  loading = false;
  currentStep = 0;
  shouldCopyCard = false;
  copySytemId = null;
  systems: SystemServices.System[] = [];
  isImprovedSystem = this.$route.params.showImprovement;
  card = _.extend(new Card(), {
    showImprovement: this.isImprovedSystem,
  });
  // Computed property for lastStep
  lastStep = 5;
  columns: Array<Column> = [];
  ruleForm!: HTMLFormElement;
  copiedCardId: null | number = null;

  // settings: Setting[] = [];
  cardSettings: CardSetting[] = [];
  configLoading = false;
  keys: Count<KeySystem> = {
    count: 0,
    rows: [],
  };
  unmatchedSettings: any[] = [];
  copiedCardRules: Rule[] = [];

  loadingSystemCards = false;
  currentPage = 1;
  perPage = 6;
  cards: Card[] = [];
  displayedCards: Card[] = [];

  showAlertRules = false;
  alertRulesDescription: null | string = null;
  showAlertSettings = false;
  alertSettingsDescription: null | string = null;

  newSetting(setting: Omit<CardSetting, "id">) {
    this.cardSettings.push({ ...setting, id: this.cardSettings.length });
  }

  deleteSetting(id: number) {
    this.cardSettings.splice(id, 1);
  }

  backStep() {
    switch (this.currentStep) {
      case 0:
        break;
      case 1:
        this.currentStep -= 1;
        break;
      case 2:
        if (!this.shouldCopyCard) {
          this.currentStep -= 2;
          break;
        }

        this.currentStep -= 1;
        break;
      case 3:
        this.currentStep -= 1;
        this.showAlertRules = false;
        break;
      case 4:
        this.currentStep -= 1;
        this.showAlertRules = true;
        this.showAlertSettings = false;
        break;
      case 5:
        if (!this.showImprovement) {
          this.currentStep -= 2;
          break;
        }

        this.showAlertSettings = true;
        this.currentStep -= 1;
        break;
      default:
        this.currentStep -= 1;
        break;
    }
  }

  nextStep() {
    switch (this.currentStep) {
      case 0:
        if (this.shouldCopyCard) {
          this.findAllSystems();
          this.currentStep += 1;
        } else {
          this.card = _.extend(new Card(), {
            showImprovement: this.isImprovedSystem,
            rules: [],
            systemId: this.systemId,
          });
          this.currentStep += 2;
        }
        break;
      case 1:
        if (this.shouldCopyCard) {
          if (this.copiedCardId) {
            this.copyCard(this.copiedCardId);
            this.currentStep += 1;
          }
        }
        break;
      case 2:
        if (this.shouldCopyCard) {
          this.validateCardForm();
          if (this.copiedCardRules.length) {
            this.showAlertRules = true;
            this.alertRulesDescription = this.formatCopiedRules(
              this.copiedCardRules
            );
          }
        } else {
          if (this.validateCardForm()) {
            this.currentStep += 1;
          }
        }
        break;
      case 3:
        //Close alert from rules
        this.showAlertRules = false;
        if (this.card.showImprovement) {
          this.currentStep += 1;
          if (this.shouldCopyCard && this.unmatchedSettings.length) {
            this.showAlertSettings = true;
            this.alertSettingsDescription = this.unmatchedSettings.join(", ");
          }
        } else {
          this.currentStep += 2; // end 2 flow
        }
        break;
      case 4:
        //Close alert from settings
        this.showAlertSettings = false;
        this.currentStep += 1;
        break;
    }
  }

  formatCopiedRules(rules: Rule[]): string {
    return rules
      .map(item => `- ${item.column.name} ${item.operator} ${item.value}`)
      .join("<br>");
  }

  validateCardForm() {
    const cardForm = this.cardForm.validateForm();

    if (!cardForm) {
      return false;
    } else {
      this.currentStep += 1;
    }
  }

  getColumns() {
    ColumnServices.find({
      order: "name",
      system: this.$route.params.system,
    })
      .then(res => {
        this.columns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  async createCard() {
    this.loading = true;
    this.ruleForm = this.$refs["ruleForm"] as HTMLFormElement;
    this.ruleForm.clearRules();
    const cardCopy = { ...this.card };
    this.loading = true;
    if (
      typeof this.card.activeDays === "undefined" ||
      (Array.isArray(this.card.activeDays) && this.card.activeDays.length === 0)
    ) {
      cardCopy.startTimeActivity = null;
      cardCopy.endTimeActivity = null;
    }
    if (this.cardForm.imageFile().has("file")) {
      const imageUrl = await CardServices.uploadImage(
        this.cardForm.imageFile()
      );
      cardCopy.b64Picture = imageUrl.data.data.url;
      this.cardForm.clearFormData();
    }

    CardServices.create(cardCopy)
      .then(res => {
        const requestsConfig = this.cardSettings.map(setting => {
          const { keySystemId, value } = setting;
          return CardSettingServices.create({
            value,
            keySystemId,
            cardId: res.data.data.id,
          });
        });
        Promise.all(requestsConfig);
        Notify.successful("Tarjeta creada exitosamente.");
        this.$router.push("/card");
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changedUrl(url: string) {
    this.card.urls = [url];
  }

  cancel() {
    this.$router.push("/card");
  }

  getKeys() {
    SystemServices.findKeys(this.systemId, {
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.keys = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  omitCardValues(card: Card, keysToDelete: string[]) {
    return _.omit(card, keysToDelete);
  }

  async findOneSystemKeys(systemId: number) {
    return await SystemServices.findKeys(systemId);
  }

  async findAllSystems() {
    try {
      const systems = await SystemServices.find({
        order: "name:asc",
        active: 1,
      });
      this.systems = systems.data.data;
    } catch (error) {
      Notify.gebServerError(error);
    }
  }

  async findSystemCards(systemId: number) {
    this.loadingSystemCards = true;
    try {
      const cards = await CardServices.find({
        systemId,
        order: "order:asc",
      });

      // After fetching all data, load the first page
      this.cards = cards.data.data;
      this.paginate(1); // Start at page 1
    } catch (error) {
      Notify.gebServerError(error);
    } finally {
      this.loadingSystemCards = false;
    }
  }

  paginate(page: number) {
    if (!this.cards.length) {
      return (this.displayedCards = []);
    }
    const totalPages = Math.ceil(this.cards.length / this.perPage); // Calculate the total number of pages

    // Ensure the new page number is within valid bounds
    if (page < 1 || page > totalPages) {
      return;
    }

    this.currentPage = page; // Set the current page
    const startIndex = (this.currentPage - 1) * this.perPage; // Calculate the starting index
    const endIndex = startIndex + this.perPage; // Calculate the ending index

    // Slice the full cards array to get only the cards for the current page
    this.displayedCards = this.cards.slice(startIndex, endIndex);
  }

  separateMatchedAndUnmatchedSettings(
    setting: any[],
    systemSettings: any[]
  ): { matched: any[]; unmatched: any[] } {
    // Step 1: Create a map of systemSettings items by key for fast lookup
    const systemSettingMap = new Map(
      systemSettings.map(item => [item.key, item])
    );

    const matched: any[] = [];
    const unmatched: any[] = [];

    // Step 2: Iterate over config items and check if the key exists in systemSettingMap
    setting.forEach(configItem => {
      const systemItem = systemSettingMap.get(configItem.key);

      if (systemItem) {
        // If a match is found, push the mapped object into the matched array
        matched.push({
          dataType: systemItem.dataType, // dataType from system setting
          keySystemId: systemItem.id, // id from system setting
          name: configItem.name, // name from setting
          value: configItem.value, // value from setting
        });
      } else {
        // If no match is found, push the configItem into the unmatched array
        unmatched.push(configItem.key);
      }
    });

    return { matched, unmatched };
  }

  async copyCard(cardId: number) {
    //Reset card settings every copied card
    this.cardSettings = [];
    const selectedCard = this.cards.find(card => card.id === cardId);
    if (!selectedCard) {
      return;
    }

    const keysFromSelectedCard = await this.findOneSystemKeys(this.systemId);

    //Obtain config that can be copied
    const { matched, unmatched } = this.separateMatchedAndUnmatchedSettings(
      selectedCard.settings,
      keysFromSelectedCard.data.data.rows
    );

    this.unmatchedSettings = unmatched;
    this.copiedCardRules = selectedCard.rules;

    matched.map((config, index) => {
      const isAdded = this.cardSettings.find(
        setting => setting.keySystemId === config.keySystemId
      );

      //Skip settings already added
      if (isAdded) return;

      this.cardSettings.push({ ...config, id: index });
    });

    this.card = _.extend(selectedCard, {
      system: this.card.system,
      systemId: this.card.systemId,
      showImprovement: this.isImprovedSystem,
      rules: [],
      url: selectedCard.urls[0].url,
    });
    this.card = this.omitCardValues(this.card, [
      "id",
      "offerTypeId",
      "order",
      "settings",
      "createdAt",
      "updatedAt",
    ]);
  }

  created() {
    const title = "Nueva tarjeta";
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Tarjetas",
        to: {
          name: "card",
        },
      },
      {
        text: title,
      },
    ]);
    this.card.rules = [];
    this.card.systemId = this.systemId;
    if (!this.card.systemId) {
      this.$router.push("/card");
    }
    this.getColumns();
    this.getKeys();
  }
}
