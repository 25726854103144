
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { stopCampaignShipment, restartCampaignShipment } from "@/api/tharbad";
import Notify from "@/utils/notifications";

@Component
export default class CardForm extends Vue {
  @Prop() shipments!: any;
  show = true;

  async stopShipment(id: number) {
    try {
      await stopCampaignShipment(id);
      Notify.successful("Envío actualizado con éxito.");
      this.show = false;
    } catch (error) {
      Notify.error();
    }
  }

  async restartShipment(id: number) {
    try {
      await restartCampaignShipment(id);
      Notify.successful("Envío actualizado con éxito.");
      this.show = false;
    } catch (error) {
      Notify.error();
    }
  }
}
