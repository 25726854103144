
import { Component, Vue } from "vue-property-decorator";
import * as rhovanion from "@/api/rhovanion";
import InventoryDrawer from "../../components/inventory/TheInventoryDrawer.vue";
import TheDocument from "../../components/inventory/TheDocuments.vue";
import Device from "@/models/Device";
import router from "@/router";
import Notify from "@/utils/notifications";

export interface History {
  id: number;
  details: string;
  "created-at": string;
  "updated-at": string | null;
  "deleted-at": string | null;
  "device-id": number;
  "history-action": { id: string; name: string };
  user: { id: string; name: string };
}

@Component({
  components: {
    TheDocument,
    InventoryDrawer,
  },
})
export default class InventoryDetails extends Vue {
  assetImages: Array<rhovanion.DataAsset> = [];
  assetDocuments: Array<rhovanion.DataAsset> = [];
  deviceDetails = {} as rhovanion.Device;
  deviceDrawer = false;
  create = false;
  reports = {} as rhovanion.Payload<rhovanion.ReportDevice>;
  detailsLoading = true;
  device = new Device();
  history: Array<History> = [];
  previewList: Array<string> = [];
  visible = false;
  visibleUnassign = false;
  document = false;

  isDevice(device: rhovanion.Device): device is rhovanion.Device {
    return "id" in device;
  }

  editDrawer() {
    this.create = false;
    // <----->
    this.device = {
      id: this.deviceDetails.id,
      "serial-number": this.deviceDetails["serial-number"],
      features: this.deviceDetails.features,
      "device-status-id": this.deviceDetails.status.id,
      "device-location-id": this.deviceDetails.location.id,
      observations: this.deviceDetails.observations,
      "purchase-value": this.deviceDetails["purchase-value"],
      "purchase-date": this.deviceDetails["purchase-date"],
      "device-model-id": this.deviceDetails.model.id,
      "device-category-id": this.deviceDetails.model.category.id,
    };
    // <----->
    this.deviceDrawer = true;
  }

  closeDrawer(value: boolean) {
    this.deviceDrawer = value;
  }

  closeDialog(value: boolean) {
    this.document = value;
  }

  formatDate(fecha: string) {
    return new Date(fecha).toLocaleDateString();
  }

  assignColor(text: string): string {
    const hexDefault = "#004677";
    const hexAsignado = "#609f1c";
    const hexDesasignado = "#ffff00";

    let hexColor = "";
    switch (text) {
      case "Dispositivo asignado":
        hexColor = hexAsignado;
        break;
      case "Dispositivo desasignado":
        hexColor = hexDesasignado;
        break;
      default:
        hexColor = hexDefault;
        break;
    }

    return hexColor;
  }

  unassign(id: number) {
    rhovanion
      .unassignDevice(id)
      .then(() => {
        Notify.successful("Dispositivo desasignado");
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.visibleUnassign = false;
        this.getDetailsDevice();
      });
  }

  historyDetails(action: string, user: string): string {
    interface Options {
      [key: string]: string;
    }
    const OPTIONS: Options = {
      "Asignar dispositivo": `Dispositivo asignado a ${user}`,
      "Desasignar dispositivo": `Dispositivo desasignado de ${user}`,
      "Dispositivo desasignado": `Dispositivo desasignado de ${user}`,
      "Dispositivo asignado": `Dispositivo asignado a ${user}`,
      "Creación de reporte": `Reporte creado por ${user}`,
    };

    return OPTIONS[action] || action + user;
  }

  deleteDevice(id: number) {
    rhovanion
      .destroyDevice(id)
      .then(res => {
        router.push({ name: "inventory-devices" });
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getDetailsDevice() {
    rhovanion.findAssets(this.$route.params.id).then(res => {
      this.assetImages = res.images;
      this.assetDocuments = res.documents;
      res.images.map(({ url }) => this.previewList.push(url));
    });
    rhovanion.findOneDevice(this.$route.params.id).then(res => {
      this.deviceDetails = res;
    });
    rhovanion.findDeviceReports(this.$route.params.id).then(res => {
      this.reports = res;
    });
    rhovanion.findDeviceHistory(this.$route.params.id).then(res => {
      this.history = res;
    });
  }

  mounted() {
    this.getDetailsDevice();
  }

  created() {
    const index = "7.1";
    const title = "Detalles";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Inventario",
        to: {
          name: "inventory-devices",
        },
      },
      {
        text: "Dispositivos",
        to: {
          name: "inventory-devices",
        },
      },
      {
        text: "Detalles",
      },
    ]);
  }

  get formatPurchaseDate() {
    let date = "";
    if (this.deviceDetails["purchase-date"]) {
      date = new Date(this.deviceDetails["purchase-date"]).toLocaleDateString();
    }
    return date;
  }
}
