
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import * as ReportServices from "@/api/helpers/Report";
import _ from "underscore";

@Component
export default class RuleForm extends Vue {
  @Prop() show!: boolean;
  @Prop() params!: any;
  size = 0;
  loading = false;
  reports: Array<any> = [];
  showDialog = false;

  getReports() {
    this.loading = true;
    let params: any = _.omit(this.params, "limit", "offset");
    params = _.pick(params, value => {
      return value.length > 0;
    });
    if (this.size) params.size = this.size;

    ReportServices.lead(params)
      .then(res => {
        this.reports = res.data.data;
        Notify.successful(`Reportes obtenidos exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  download(index: number) {
    const content = this.reports[index].data;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([content]));
    link.setAttribute("download", this.reports[index].name);
    document.body.appendChild(link);
    link.click();
  }

  closeDialog() {
    this.$emit("closeDialog", false);
  }

  @Watch("show")
  changedShow(value: boolean) {
    this.showDialog = value;
    if (this.show) {
      this.size = 0;
      this.loading = false;
      this.reports = [];
    }
  }
}
