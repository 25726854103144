import axios, { AxiosInstance } from "axios";
import { Gondor, Tokens, TokenType, Header, Service } from "../Gondor";
import Interceptor from "../helpers/Interceptor";

export default class TharbadClient {
  private static instance: TharbadClient;
  private http: AxiosInstance;
  private constructor() {
    this.http = axios.create({ baseURL: process.env.VUE_APP_THARBAD_URL });
    this._setAuthorizationHeader();
  }
  public static getInstance(): TharbadClient {
    if (!TharbadClient.instance) {
      TharbadClient.instance = new TharbadClient();
    }
    return TharbadClient.instance;
  }
  private _setAuthorizationHeader() {
    const accessToken = localStorage.getItem(Tokens.ACCESS);
    const tokenType = localStorage.getItem(TokenType.Default);
    if (accessToken !== null && tokenType !== null) {
      this.http.defaults.headers.common[
        Header.AUTHORIZATION
      ] = `${tokenType} ${accessToken}`;
    }
  }
  async find<T>(path: string, params: T) {
    return await this.http.get(path, { params });
  }
  async patch<T>(path: string, params: T) {
    return await this.http.patch(path, { ...params });
  }
  async post<T>(path: string, params: T) {
    return await this.http.post(path, { ...params });
  }
  async delete(path: string) {
    return await this.http.delete(path);
  }
}
