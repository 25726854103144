export default class Config {
  id?: number;
  key!: string;
  keySystemId?: number;
  datType?: string;
  value!: string;
  name!: string;
  description?: string;
  specificId!: number;
}
