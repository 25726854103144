export default class Device {
  id?: number;
  "serial-number"?: string | null;
  features?: string | null;
  "device-status-id"!: number; //status
  "device-location-id"!: number; //location
  observations?: string | null; //observations
  "purchase-value"?: number | null;
  "purchase-date"?: Date | null;
  createdAt?: Date;
  updatedAt?: Date;
  "device-model-id"!: number | undefined; //model
  "device-category-id"?: number;
}
