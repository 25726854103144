import Auth from "../api/helpers/Auth";
import { State } from "./state";

const mutations = {
  [Auth.Action.REQUEST]: (state: State) => {
    state.status = "loading";
    state.authenticated = false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [Auth.Action.SUCCESS]: (state: State, resp: object) => {
    state.status = "success";
    state.authenticated = true;
  },
  [Auth.Action.ERROR]: (state: State) => {
    state.status = "error";
    state.authenticated = false;
  },
  [Auth.Action.LOGOUT]: (state: State) => {
    state.status = "logout";
    state.authenticated = false;
  },
  [Auth.Action.ME]: (state: State) => {
    state.authenticated = true;
  },
  updateCurrentTitle: (state: State, currentTitle: string) => {
    state.currentTitle = currentTitle;
  },
  updateBreadcrumbItems: (state: State, breadcrumbItems: Array<object>) => {
    const home = { text: "Home", to: "/" };
    breadcrumbItems.unshift(home);
    state.breadcrumbItems = breadcrumbItems;
  },
  updateCurrentMenuIndex: (state: State, currentMenuIndex: string) => {
    state.currentMenuIndex = currentMenuIndex;
  },
  updateCollapseSidebar: (state: State, collapseSidebar: boolean) => {
    state.collapseSidebar = collapseSidebar;
  },
};

export default mutations;
