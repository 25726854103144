import { Gondor } from "../Gondor";

export type Operator = "<" | ">" | "<>" | "<=" | ">=" | "=";

export interface ApiRule {
  id: number;
  value: string;
  operator: Operator;
  apiSystemId: number;
  columnId: number;
  createdAt: Date;
  updatedAt: Date;
}

interface ApiRuleSchema {
  value: string;
  operator: Operator;
  apiSystemId: number;
  columnId: number;
}

const ACTIONS = {
  APIRULE: "/apiRule",
  APIRULE_ITEM: "/apiRule/{id}",
};

export const create = (body: ApiRuleSchema) => {
  return Gondor.API.post(ACTIONS.APIRULE, body);
};

export const update = (ruleId: number, body: Partial<ApiRuleSchema>) => {
  return Gondor.API.patch(
    ACTIONS.APIRULE_ITEM.replace("{id}", String(ruleId)),
    body
  );
};

export const destroy = (ruleId: number) => {
  return Gondor.API.delete(
    ACTIONS.APIRULE_ITEM.replace("{id}", String(ruleId))
  );
};
