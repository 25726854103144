
import { Component, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import Catalog from "@/models/Catalog";
import CatalogCard from "@/components/CatalogCard.vue";
import CatalogDrawer from "@/components/TheCatalogDrawer.vue";
import * as DebtServices from "@/api/helpers/Debt";
import * as StateServices from "@/api/helpers/State";
import * as LenderServices from "@/api/helpers/Lender";
import * as IncomeServices from "@/api/helpers/Income";
import * as SystemServices from "@/api/helpers/System";
import * as ProductServices from "@/api/helpers/Product";
import * as ProfessionServices from "@/api/helpers/Profession";
import * as GenderServices from "@/api/helpers/Gender";
import * as MaritalStatusServices from "@/api/helpers/MaritalStatus";
import * as PhoneBrandServices from "@/api/helpers/PhoneBrand";
import * as PhoneCompanyServices from "@/api/helpers/PhoneCompany";
import * as DepositServices from "@/api/helpers/Deposit";
import * as MaxPriceServices from "@/api/helpers/MaxPrice";
import * as MonthlyPaymentServices from "@/api/helpers/MonthlyPayment";
import * as VehicleSizeServices from "@/api/helpers/VehicleSize";
import * as VehicleTypeServices from "@/api/helpers/VehicleType";
import * as VehiclePurposeServices from "@/api/helpers/VehiclePurpose";
import * as FeatureServices from "@/api/helpers/Feature";
import * as CompanyActivityServices from "@/api/helpers/CompanyActivity";
import * as CompanyEmployeesServices from "@/api/helpers/CompanyEmployees";
import * as CompanyYearsServices from "@/api/helpers/CompanyYears";
import * as DedicatedTimeServices from "@/api/helpers/DedicatedTime";
import * as InvestmentServices from "@/api/helpers/Investment";
import * as MotivationServices from "@/api/helpers/Motivation";
import * as SatServices from "@/api/helpers/Sat";
import * as SchoolGradeServices from "@/api/helpers/SchoolGrade";
import * as StudiesFieldServices from "@/api/helpers/StudiesField";

@Component({
  components: {
    CatalogDrawer,
    CatalogCard,
  },
})
export default class CatalogView extends Vue {
  fullscreenLoading = true;
  create = false;
  catalogLoading: {
    [key: string]: boolean;
  } = {
    state: false,
    income: false,
    product: false,
    profession: false,
    debt: false,
    lender: false,
    gender: false,
    maritalStatus: false,
    phoneBrand: false,
    phoneCompany: false,
    deposit: false,
    maxPrice: false,
    monthlyPayment: false,
    vehicleSize: false,
    vehicleType: false,
    vehiclePurpose: false,
    feature: false,
    companyActivity: false,
    companyEmployees: false,
    companyYears: false,
    dedicatedTime: false,
    investment: false,
    motivation: false,
    sat: false,
    schoolGrade: false,
    studiesField: false,
  };
  showDrawer = false;
  loadingDrawer = false;
  catalog = new Catalog();
  debts: Array<Catalog> = [];
  states: Array<Catalog> = [];
  incomes: Array<Catalog> = [];
  lenders: Array<Catalog> = [];
  products: Array<Catalog> = [];
  professions: Array<Catalog> = [];
  genders: Array<Catalog> = [];
  maritalStatuses: Array<Catalog> = [];
  phoneBrands: Array<Catalog> = [];
  phoneCompanies: Array<Catalog> = [];
  deposits: Array<Catalog> = [];
  maxPrices: Array<Catalog> = [];
  monthlyPayments: Array<Catalog> = [];
  vehicleSizes: Array<Catalog> = [];
  vehicleTypes: Array<Catalog> = [];
  vehiclePurposes: Array<Catalog> = [];
  features: Array<Catalog> = [];
  companyActivities: Array<Catalog> = [];
  companyEmployees: Array<Catalog> = [];
  companyYears: Array<Catalog> = [];
  dedicatedTime: Array<Catalog> = [];
  investment: Array<Catalog> = [];
  motivation: Array<Catalog> = [];
  sat: Array<Catalog> = [];
  schoolGrade: Array<Catalog> = [];
  studiesField: Array<Catalog> = [];
  systems = [];
  title = "";
  type = "";
  hasSystems = true;
  catalogHasSystems: {
    [key: string]: boolean;
  } = {
    state: true,
    income: true,
    product: true,
    profession: true,
    debt: true,
    lender: true,
    gender: false,
    maritalStatus: false,
    phoneBrand: false,
    phoneCompany: false,
    deposit: true,
    maxPrice: true,
    monthlyPayment: true,
    vehicleSize: true,
    vehicleType: true,
    vehiclePurpose: true,
    feature: true,
    companyActivity: true,
    companyEmployees: true,
    companyYears: true,
    dedicatedTime: true,
    investment: true,
    motivation: true,
    sat: true,
    schoolGrade: true,
    studiesField: true,
  };
  titleTypes: {
    [key: string]: {
      [key: string]: string;
    };
  } = {
    state: {
      create: "Crear nuevo estado",
      update: "Actualizar estado",
    },
    income: {
      create: "Crear nuevo ingreso mensual",
      update: "Actualizar ingreso mensual",
    },
    product: {
      create: "Crear nuevo producto financiero",
      update: "Actualizar producto financiero",
    },
    profession: {
      create: "Crear nuevo estatus laboral",
      update: "Actualizar estatus laboral",
    },
    debt: {
      create: "Crear nuevo monto de deuda",
      update: "Actualizar monto de deuda",
    },
    lender: {
      create: "Crear nuevo prestamista",
      update: "Actualizar prestamista",
    },
    gender: {
      create: "Agregar género",
      update: "Actualizar género",
    },
    maritalStatus: {
      create: "Agregar estado civil",
      update: "Actualizar estado civil",
    },
    phoneBrand: {
      create: "Agregar marca de teléfono",
      update: "Actualizar marca de teléfono",
    },
    phoneCompany: {
      create: "Agregar proveedor de linea telefónica",
      update: "Actualizar proveedor de linea telefónica",
    },
    deposit: {
      create: "Agrega un nuevo enganche de auto",
      update: "Actualiza un enganche de auto",
    },
    maxPrice: {
      create: "Agrega un nuevo presupuesto (costo máximo) de auto",
      update: "Actualiza un presupuesto (costo máximo) de auto",
    },
    monthlyPayment: {
      create: "Agrega una nueva mensualidad de auto",
      update: "Actualiza una mensualidad de auto",
    },
    vehicleSize: {
      create: "Agrega un nuevo tamaño de vehiculo",
      update: "Actualiza un tamaño de vehiculo",
    },
    vehicleType: {
      create: "Agrega un nuevo tipo de vehiculo",
      update: "Actualiza un tipo de vehiculo",
    },
    vehiclePurpose: {
      cretae: "Agrega un nuevo proposito de vehiculo",
      update: "Actualiza un proposito de vehiculo",
    },
    feature: {
      create: "Agrega una nueva caracteristica de vehiculo",
      update: "Actualiza una caracteristica de vehiculo",
    },
    companyActivity: {
      create: "Agrega la actividad a la que se dedica la empresa",
      update: "Actualiza la actividad a la que se dedica la empresa",
    },
    companyEmployees: {
      create: "Agrega el número de empleados de la empresa",
      update: "Actualiza el número de empleados de la empresa",
    },
    companyYears: {
      create: "Agrega los años que la empresa lleva operando",
      update: "Actualiza los años que la empresa lleva operando",
    },
    dedicatedTime: {
      create: "Agregar nuevo tiempo a dedicar",
      update: "Actualiza tiempo a dedicar",
    },
    investment: {
      create: "Agregar inversión",
      update: "Actualiza inversión",
    },
    motivation: {
      create: "Agregar nueva modalidad",
      update: "Actualiza modalidad",
    },
    sat: {
      create: "SAT",
      update: "SAT",
    },
    schoolGrade: {
      create: "Agregar nuevo interés de estudio",
      update: "Actualiza el interés de estudio",
    },
    studiesField: {
      create: "Agregar nuevo área de estudio",
      update: "Actualiza el área de estudio",
    },
  };

  addSystems(
    selectedSystems: Array<number>,
    selectedCatalogs: Array<number>,
    type: string
  ) {
    const { CatalogServices, getCatalog } = this.setCatalog(type);
    this.catalogLoading[type] = true;
    const body: {
      [key: string]: Array<number>;
    } = {};
    body["systems"] = selectedSystems;
    body[`${type}s`] = selectedCatalogs;
    CatalogServices.addSystems(body)
      .then(() => {
        getCatalog();
        Notify.successful(`Operación realizada exitosamente.`);
      })
      .catch((error: any) => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[type] = false;
        this.closeDrawer();
      });
  }

  removeSystems(
    selectedSystems: Array<number>,
    selectedCatalogs: Array<number>,
    type: string
  ) {
    const { CatalogServices, getCatalog } = this.setCatalog(type);
    this.catalogLoading[type] = true;
    const body: {
      [key: string]: Array<number>;
    } = {};
    body["systems"] = selectedSystems;
    body[`${type}s`] = selectedCatalogs;
    CatalogServices.removeSystems(body)
      .then(() => {
        getCatalog();
        Notify.successful(`Operación realizada exitosamente.`);
      })
      .catch((error: any) => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[type] = false;
        this.closeDrawer();
      });
  }

  createCatalog(name: string, systems: Array<number> | null, type: string) {
    const { CatalogServices, getCatalog } = this.setCatalog(type);
    this.loadingDrawer = true;
    this.catalogLoading[type] = true;
    CatalogServices.create({
      name,
      systems,
    })
      .then(() => {
        getCatalog();
        Notify.successful(`${name} creado exitosamente.`);
      })
      .catch((error: any) => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[type] = false;
        this.closeDrawer();
      });
  }

  updateCatalog(
    id: number,
    name: string,
    systems: Array<number>,
    type: string
  ) {
    this.loadingDrawer = true;
    const { CatalogServices, getCatalog } = this.setCatalog(type);
    CatalogServices.update(id, {
      name,
      systems,
    })
      .then(() => {
        getCatalog();
        Notify.successful(`${name} actualizado exitosamente.`);
      })
      .catch((error: any) => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.closeDrawer();
      });
  }

  deleteCatalog(id: number, type: string) {
    const { CatalogServices, getCatalog } = this.setCatalog(type);
    this.catalogLoading[type] = true;
    CatalogServices.destroy(id)
      .then((res: any) => {
        getCatalog();
        Notify.successful(`${res.data.data.name} eliminado exitosamente.`);
      })
      .catch((error: any) => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[type] = false;
      });
  }

  setCatalog(type: string) {
    let CatalogServices: any;
    let getCatalog: any;
    switch (type) {
      case "state":
        CatalogServices = StateServices;
        getCatalog = this.getStates;
        break;
      case "income":
        CatalogServices = IncomeServices;
        getCatalog = this.getIncomes;
        break;
      case "product":
        CatalogServices = ProductServices;
        getCatalog = this.getProducts;
        break;
      case "profession":
        CatalogServices = ProfessionServices;
        getCatalog = this.getProfessions;
        break;
      case "debt":
        CatalogServices = DebtServices;
        getCatalog = this.getDebts;
        break;
      case "lender":
        CatalogServices = LenderServices;
        getCatalog = this.getLenders;
        break;
      case "gender":
        CatalogServices = GenderServices;
        getCatalog = this.getGenders;
        break;
      case "maritalStatus":
        CatalogServices = MaritalStatusServices;
        getCatalog = this.getMaritalStatuses;
        break;
      case "phoneBrand":
        CatalogServices = PhoneBrandServices;
        getCatalog = this.getPhoneBrands;
        break;
      case "phoneCompany":
        CatalogServices = PhoneCompanyServices;
        getCatalog = this.getPhoneCompanies;
        break;
      case "deposit":
        CatalogServices = DepositServices;
        getCatalog = this.getDeposits;
        break;
      case "maxPrice":
        CatalogServices = MaxPriceServices;
        getCatalog = this.getMaxPrices;
        break;
      case "monthlyPayment":
        CatalogServices = MonthlyPaymentServices;
        getCatalog = this.getMonthlyPayments;
        break;
      case "vehicleSize":
        CatalogServices = VehicleSizeServices;
        getCatalog = this.getVehicleSizes;
        break;
      case "vehicleType":
        CatalogServices = VehicleTypeServices;
        getCatalog = this.getVehicleTypes;
        break;
      case "vehiclePurpose":
        CatalogServices = VehiclePurposeServices;
        getCatalog = this.getVehiclePurposes;
        break;
      case "feature":
        CatalogServices = FeatureServices;
        getCatalog = this.getFeatures;
        break;
      case "companyActivity":
        CatalogServices = CompanyActivityServices;
        getCatalog = this.getCompanyActivities;
        break;
      case "companyEmployees":
        CatalogServices = CompanyEmployeesServices;
        getCatalog = this.getCompanyEmployees;
        break;
      case "companyYears":
        CatalogServices = CompanyYearsServices;
        getCatalog = this.getCompanyYears;
        break;
      case "dedicatedTime":
        CatalogServices = DedicatedTimeServices;
        getCatalog = this.getDedicatedTime;
        break;
      case "investment":
        CatalogServices = InvestmentServices;
        getCatalog = this.getInvestment;
        break;
      case "motivation":
        CatalogServices = MotivationServices;
        getCatalog = this.getMotivation;
        break;
      case "sat":
        CatalogServices = SatServices;
        getCatalog = this.getSat;
        break;
      case "schoolGrade":
        CatalogServices = SchoolGradeServices;
        getCatalog = this.getSchoolGrade;
        break;
      case "studiesField":
        CatalogServices = StudiesFieldServices;
        getCatalog = this.getStudiesField;
        break;
    }
    return {
      CatalogServices,
      getCatalog,
    };
  }

  newDrawer(type: string) {
    this.create = true;
    this.catalog = new Catalog();
    this.showDrawer = true;
    this.type = type;
    this.title = this.titleTypes[type]["create"];
    this.hasSystems = this.catalogHasSystems[type];
  }

  editDrawer(catalog: Catalog, type: string) {
    this.create = false;
    this.catalog = catalog;
    this.showDrawer = true;
    this.type = type;
    this.title = this.titleTypes[type]["update"];
    this.hasSystems = this.catalogHasSystems[type];
  }

  closeDrawer() {
    this.loadingDrawer = false;
    this.showDrawer = false;
    this.catalog = new Catalog();
  }

  getStates() {
    const TYPE = "state";
    this.catalogLoading[TYPE] = true;
    StateServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.states = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getIncomes() {
    const TYPE = "income";
    this.catalogLoading[TYPE] = true;
    IncomeServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.incomes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getProducts() {
    const TYPE = "product";
    this.catalogLoading[TYPE] = true;
    ProductServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.products = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getProfessions() {
    const TYPE = "profession";
    this.catalogLoading[TYPE] = true;
    ProfessionServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.professions = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getDebts() {
    const TYPE = "debt";
    this.catalogLoading[TYPE] = true;
    DebtServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.debts = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getLenders() {
    const TYPE = "lender";
    this.catalogLoading[TYPE] = true;
    LenderServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.lenders = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getGenders() {
    const TYPE = "gender";
    this.catalogLoading[TYPE] = true;
    GenderServices.find({
      order: "description:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.genders = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getMaritalStatuses() {
    const TYPE = "maritalStatus";
    this.catalogLoading[TYPE] = true;
    MaritalStatusServices.find({
      order: "description:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.maritalStatuses = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getPhoneBrands() {
    const TYPE = "phoneBrand";
    this.catalogLoading[TYPE] = true;
    PhoneBrandServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.phoneBrands = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getPhoneCompanies() {
    const TYPE = "phoneCompany";
    this.catalogLoading[TYPE] = true;
    PhoneCompanyServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.phoneCompanies = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getDeposits() {
    const TYPE = "deposit";
    this.catalogLoading[TYPE] = true;
    DepositServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.deposits = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getMaxPrices() {
    const TYPE = "maxPrice";
    this.catalogLoading[TYPE] = true;
    MaxPriceServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.maxPrices = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getMonthlyPayments() {
    const TYPE = "monthlyPayment";
    this.catalogLoading[TYPE] = true;
    MonthlyPaymentServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.monthlyPayments = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getVehicleSizes() {
    const TYPE = "vehicleSize";
    this.catalogLoading[TYPE] = true;
    VehicleSizeServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.vehicleSizes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getVehicleTypes() {
    const TYPE = "vehicleType";
    this.catalogLoading[TYPE] = true;
    VehicleTypeServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.vehicleTypes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getVehiclePurposes() {
    const TYPE = "vehiclePurpose";
    this.catalogLoading[TYPE] = true;
    VehiclePurposeServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.vehiclePurposes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getFeatures() {
    const TYPE = "feature";
    this.catalogLoading[TYPE] = true;
    FeatureServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.features = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getCompanyActivities() {
    const TYPE = "companyActivity";
    this.catalogLoading[TYPE] = true;
    CompanyActivityServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.companyActivities = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getCompanyEmployees() {
    const TYPE = "companyEmployees";
    this.catalogLoading[TYPE] = true;
    CompanyEmployeesServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.companyEmployees = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getCompanyYears() {
    const TYPE = "companyYears";
    this.catalogLoading[TYPE] = true;
    CompanyYearsServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.companyYears = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getDedicatedTime() {
    const TYPE = "dedicatedTime";
    this.catalogLoading[TYPE] = true;
    DedicatedTimeServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.dedicatedTime = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getInvestment() {
    const TYPE = "investment";
    this.catalogLoading[TYPE] = true;
    InvestmentServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.investment = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }
  getMotivation() {
    const TYPE = "motivation";
    this.catalogLoading[TYPE] = true;
    MotivationServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.motivation = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getSat() {
    const TYPE = "sat";
    this.catalogLoading[TYPE] = true;
    SatServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.sat = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  getSystems() {
    SystemServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.systems = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getSchoolGrade() {
    const TYPE = "schoolGrade";
    this.catalogLoading[TYPE] = true;
    SchoolGradeServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.schoolGrade = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }
  getStudiesField() {
    const TYPE = "studiesField";
    this.catalogLoading[TYPE] = true;
    StudiesFieldServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.fullscreenLoading = false;
        this.studiesField = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.catalogLoading[TYPE] = false;
      });
  }

  created() {
    const index = "12";
    const title = "Catálogos";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getDebts();
    this.getStates();
    this.getLenders();
    this.getIncomes();
    this.getSystems();
    this.getProducts();
    this.getProfessions();
    this.getGenders();
    this.getMaritalStatuses();
    this.getPhoneBrands();
    this.getPhoneCompanies();
    this.getDeposits();
    this.getMaxPrices();
    this.getMonthlyPayments();
    this.getVehicleSizes();
    this.getVehicleTypes();
    this.getVehiclePurposes();
    this.getFeatures();
    this.getCompanyActivities();
    this.getCompanyEmployees();
    this.getCompanyYears();
    this.getDedicatedTime();
    this.getInvestment();
    this.getMotivation();
    this.getSat();
    this.getSchoolGrade();
    this.getStudiesField();
  }
}
