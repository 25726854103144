
import { Component, Vue } from "vue-property-decorator";
import TheBreadcrumb from "@/components/TheBreadcrumb.vue";
import User from "@/models/User";
import Notify from "@/utils/notifications";
import Auth from "@/api/helpers/Auth";

@Component({
  components: {
    TheBreadcrumb,
  },
})
export default class TheNavBar extends Vue {
  user = new User();
  logoutLoading = false;

  logout() {
    this.logoutLoading = true;
    this.$store
      .dispatch(Auth.Action.LOGOUT)
      .then(() => {
        Notify.successful("Cierre de sessión exitoso.");
        this.$router.push("/login");
      })
      .catch(error => {
        if (typeof error.response !== "undefined") {
          Notify.gebServerError(error);
        } else {
          Notify.gebServerError({
            response: { data: { message: "", data: { errors: [error] } } },
          });
        }
      })
      .finally(() => {
        this.logoutLoading = false;
      });
  }

  loggedUser() {
    const user = localStorage.getItem(Auth.Action.ME);
    if (!user) {
      this.$store
        .dispatch(Auth.Action.ME)
        .then(res => {
          this.user = res.data.data;
          Notify.successful(`Bienvenido ${this.user.name}`);
        })
        .catch(error => {
          Notify.gebServerError(error);
        });
    } else {
      this.user = JSON.parse(user);
    }
  }

  created() {
    this.loggedUser();
  }

  get currentTitle() {
    return this.$store.getters.currentTitle;
  }
  get breadcrumbItems() {
    return this.$store.getters.breadcrumbItems;
  }
}
