
import { Component, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import LoanRuleDrawer from "@/components/Liink/LoanRuleDrawer.vue";
import LoanRuleCard from "@/components/Liink/LoanRuleCard.vue";
import Liink from "@/api/Liink";
import LoanRule from "@/models/Liink/LoanRule";
import Lender from "@/models/Liink/Lender";
import Catalog from "@/models/Liink/Catalog";

@Component({
  components: {
    LoanRuleCard,
    LoanRuleDrawer,
  },
})
export default class CatalogView extends Vue {
  loading = false;
  loadingDrawer = false;
  rule: LoanRule = {} as LoanRule;
  rules: Array<LoanRule> = [];
  lenders: Array<Lender> = [];
  employmentStatus: Array<Catalog> = [];
  employmentTimeWorked: Array<Catalog> = [];
  drawer = false;
  create = false;

  async createRule(data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.createLoanRule(data);

      Notify.successful("La regla se creó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async updateRule(id: number, data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.updateLoanRule(id, data);

      Notify.successful("La regla se actualizó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async deleteRule(id: number) {
    try {
      await Liink.deleteLoanRule(id);

      Notify.successful("La regla se eliminó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.load();
  }

  openDrawer(rule?: LoanRule) {
    this.create = rule == undefined;
    this.rule = rule
      ? rule
      : ({
          isActive: true,
          CLABE: false,
          propertyOwner: false,
          bankAccount: false,
          proofOfIncome: false,
        } as LoanRule);
    this.drawer = true;
  }

  closeDrawer() {
    this.loadingDrawer = false;
    this.drawer = false;
    this.rule = {} as LoanRule;
  }

  async load() {
    this.loading = true;

    try {
      this.lenders = await Liink.getLenders();
    } catch (err) {
      console.error(err);
    }

    try {
      this.employmentStatus = await Liink.getCatalog("employment-status");
    } catch (err) {
      console.error(err);
    }

    try {
      this.employmentTimeWorked = await Liink.getCatalog(
        "employment-time-worked"
      );
    } catch (err) {
      console.error(err);
    }

    try {
      this.rules = await Liink.getLoanRules();
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  created() {
    const title = "Reglas de préstamos";
    const index = "5.3";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Liink",
        to: "/liink",
      },
      {
        text: title,
      },
    ]);

    this.load();
  }
}
