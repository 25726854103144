
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CardCount extends Vue {
  @Prop() type!: string;
  @Prop() dataType!: string;
  @Prop() name!: string;
  @Prop() data!: any;

  visible = false;
  loading = true;
  variant = "success";
  textVariant = "white";
  borderColor = "";
  totalBackground = "";

  dateRange = [
    new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
  ];

  apply() {
    this.loading = true;

    const query = {
      start:
        this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null,
      end:
        this.dateRange && this.dateRange.length > 1 ? this.dateRange[1] : null,
      type: this.dataType,
    };
    this.$emit("GetCount", query, () => {
      this.loading = false;
    });
  }

  updated() {
    this.variant = this.data.error ? "warning" : "success";
    this.textVariant = this.data.error ? "gray" : "white";
    this.borderColor =
      this.type === "success"
        ? "#c2e7b0"
        : this.type === "primary"
        ? "#b3d8ff"
        : "#dcdfe6";
    this.totalBackground =
      this.type === "success"
        ? "#f0f9eb"
        : this.type === "primary"
        ? "#ecf5ff"
        : "#fff";
  }

  mounted() {
    this.apply();
  }
}
