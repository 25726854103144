import System from "@/models/System";

export default class Column {
  id?: number;
  name!: string;
  code!: string;
  dataType!: string;
  active = true;
  previousAnswer = false;
  catalog = false;
  createdAt?: Date;
  updatedAt?: Date;
  systems?: Array<System | number>;
}
