
import { Component, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import _ from "underscore";
import * as CountryServices from "@/api/helpers/Country";
import * as CostServices from "@/api/helpers/CampaignCost";
import CardCostExpandTable from "@/components/CardCostExpandTable.vue";

@Component({
  components: { CardCostExpandTable },
})
export default class Cards extends Vue {
  fullscreenLoading = false;
  loadingCountries = true;
  form = {
    investmentDate: [
      new Date(
        new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)
      ).toISOString(),
      new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
    ],
    countryId: null,
  };
  countries: Array<CountryServices.CountryCode> = [];
  systemsCosts = [
    // {
    //   id: 1,
    //   system: "Credisi",
    //   cards: [
    //     {
    //       id: 1,
    //       name: "Resuelve Tu Deuda",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 2,
    //       name: "Solventa",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //     {
    //       id: 3,
    //       name: "RapiCredit",
    //       investment: 8554,
    //       clics: 8354,
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   system: "Prestatodos",
    //   cards: [
    //     {
    //       id: 4,
    //       name: "HSBC",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 5,
    //       name: "SolCredito",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //     {
    //       id: 6,
    //       name: "Fintonic",
    //       investment: 8554,
    //       clics: 8354,
    //       active: true,
    //     },
    //     {
    //       id: 7,
    //       name: "AMEX SBS",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   id: 1,
    //   system: "Credisi",
    //   cards: [
    //     {
    //       id: 1,
    //       name: "Resuelve ",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 2,
    //       name: "Solventa",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   system: "Prestatodos",
    //   cards: [
    //     {
    //       id: 4,
    //       name: "HSBC",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 5,
    //       name: "SolCredito",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //     {
    //       id: 6,
    //       name: "Fintonic",
    //       investment: 8554,
    //       clics: 8354,
    //       active: true,
    //     },
    //     {
    //       id: 7,
    //       name: "AMEX SBS",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   id: 1,
    //   system: "Credisi",
    //   cards: [
    //     {
    //       id: 1,
    //       name: "Resuelve Tu Deuda",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 2,
    //       name: "Solventa",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //     {
    //       id: 3,
    //       name: "RapiCredit",
    //       investment: 8554,
    //       clics: 8354,
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   system: "Prestatodos",
    //   cards: [
    //     {
    //       id: 4,
    //       name: "HSBC",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 5,
    //       name: "SolCredito",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //     {
    //       id: 6,
    //       name: "Fintonic",
    //       investment: 8554,
    //       clics: 8354,
    //       active: true,
    //     },
    //     {
    //       id: 7,
    //       name: "AMEX SBS",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   id: 1,
    //   system: "Credisi",
    //   cards: [
    //     {
    //       id: 1,
    //       name: "Resuelve Tu Deuda",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 2,
    //       name: "Solventa",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //     {
    //       id: 3,
    //       name: "RapiCredit",
    //       investment: 8554,
    //       clics: 8354,
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   system: "Prestatodos",
    //   cards: [
    //     {
    //       id: 4,
    //       name: "HSBC",
    //       investment: 12754,
    //       clics: 15040,
    //       active: true,
    //     },
    //     {
    //       id: 5,
    //       name: "SolCredito",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //     {
    //       id: 6,
    //       name: "Fintonic",
    //       investment: 8554,
    //       clics: 8354,
    //       active: true,
    //     },
    //     {
    //       id: 7,
    //       name: "AMEX SBS",
    //       investment: 7754,
    //       clics: 8085,
    //       active: false,
    //     },
    //   ],
    // },
  ];

  getSystems() {
    this.systemsCosts = _.chunk(this.systemsCosts, 3);
    this.fullscreenLoading = false;
  }

  getCosts() {
    this.fullscreenLoading = true;
    if (!this.form.countryId) delete this.form.countryId;
    CostServices.cost(this.form)
      .then(res => {
        this.systemsCosts = _.map(res.data.data, (s: any) => {
          s.acquisitionChannels = _.groupBy(
            s.cards,
            (c: any) => c.acquisitionChannel.id
          );
          s.acquisitionChannels = _.map(s.acquisitionChannels, c => {
            return {
              id: c[0].id,
              name: c[0].acquisitionChannel.name,
              sources: c[0].acquisitionChannel.sources,
              cards: c,
              clicks: _.reduce(
                c,
                function(memo, num) {
                  return memo + num.clicks;
                },
                0
              ),
              investment: _.reduce(
                c,
                function(memo, num) {
                  return memo + num.investment;
                },
                0
              ),
            };
          });
          return {
            id: s.id,
            name: s.name,
            acquisitionChannels: s.acquisitionChannels,
          };
        });

        this.systemsCosts = _.map(this.systemsCosts, sc => {
          sc.acquisitionChannels = _.map(sc.acquisitionChannels, ac => {
            ac.cards = _.groupBy(ac.cards, (c: any) => c.id);
            ac.cards = _.map(ac.cards, c => {
              return {
                active: c[0].active,
                clicks: _.reduce(
                  c,
                  function(memo, num) {
                    return memo + num.clicks;
                  },
                  0
                ),
                id: c[0].id,
                investment: _.reduce(
                  c,
                  function(memo, num) {
                    return memo + num.investment;
                  },
                  0
                ),
                name: c[0].name,
                details: _.map(c, card => {
                  return _.omit(card, "id", "name");
                }),
              };
            });
            return ac;
          });
          return sc;
        });
        this.systemsCosts = _.chunk(this.systemsCosts, 3);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  getCountries() {
    CountryServices.findCountryCodes()
      .then(res => {
        this.loadingCountries = true;
        this.countries = res;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loadingCountries = false;
      });
  }

  getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = "Totales";
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value))) {
        const currency = index == 2 ? "" : "$";
        sums[index] =
          currency +
          values
            .reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0)
            .toLocaleString("en-US");
      } else {
        sums[index] = "";
      }
    });

    return sums;
  }

  created() {
    const index = "2.2";
    const title = "Costos por Tarjeta";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Tarjetas",
        to: {
          name: "card",
        },
      },
      {
        text: title,
      },
    ]);
    this.getCountries();
    this.getCosts();
  }
}
