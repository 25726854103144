
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { JourneyStep, findJourneySteps } from "@/api/tharbad";
import { bool2IconFilter } from "@/filters";
import _ from "underscore";

@Component({ components: {}, filters: { bool2IconFilter } })
export default class JourneyStepsExpandTable extends Vue {
  @Prop() readonly journeyId!: number;
  @Prop() readonly countryCode!: string;
  @PropSync("steps", { default: undefined }) syncedSteps?: JourneyStep[];

  activeSteps = {};

  loadSteps() {
    findJourneySteps(this.journeyId).then(steps => {
      this.syncedSteps = steps;
      this.$emit("toggleStepsIcon", this.journeyId, false);
    });
  }

  mounted() {
    if (this.syncedSteps === undefined) {
      this.$emit("toggleStepsIcon", this.journeyId, true);
      this.loadSteps();
    } else this.activeSteps = _.groupBy(this.syncedSteps, "active");
  }
}
