enum Action {
  REQUEST = "request",
  SUCCESS = "success",
  ERROR = "error",
  LOGOUT = "logout",
  ME = "me",
}

export default {
  Action,
};
