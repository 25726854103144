import { Gondor } from "../Gondor";

const ACTIONS = {
  MARITAL_STATUS: "/maritalStatus/",
  MARITAL_STATUS_ITEM: "/maritalStatus/{id}/",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.MARITAL_STATUS, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.MARITAL_STATUS_ITEM.replace("{id}", id));
}

export function create(body: { [name: string]: string }) {
  const payload = { description: body.name };
  return Gondor.API.post(ACTIONS.MARITAL_STATUS, payload);
}

export function update(id: number | string, body: { [name: string]: string }) {
  id = id.toString();
  const payload = { description: body.name };
  return Gondor.API.patch(
    ACTIONS.MARITAL_STATUS_ITEM.replace("{id}", id),
    payload
  );
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.MARITAL_STATUS_ITEM.replace("{id}", id));
}
