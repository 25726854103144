
import { Component, Prop, Vue } from "vue-property-decorator";
import { Template } from "@/api/tharbad";
import { CatalogItem } from "@/models/Catalog";
@Component({
  components: {},
})
export default class TemplateForm extends Vue {
  @Prop() item!: Template;
  @Prop() countries!: CatalogItem<string>[];

  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    content: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    sender: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "country-code": [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        length: 2,
        message: "Debe estar en formato ISO 3166-1-Alfa-2.",
      },
    ],
  };
}
