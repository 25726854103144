
import { Component, Prop, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import Lender from "@/models/Liink/Lender";
import CardRule from "@/models/Liink/CardRule";
import Catalog from "@/models/Liink/Catalog";

@Component({ components: { DeletePopover } })
export default class CardRuleCard extends Vue {
  @Prop() rules!: Array<CardRule>;
  @Prop() lenders!: Array<Lender>;
  @Prop() employmentStatusCatalog!: Array<Catalog>;
  @Prop() employmentTimeWorkedCatalog!: Array<Catalog>;
  @Prop() cardTypes!: Array<Catalog>;

  deleteRule(id: number) {
    this.$emit("delete", id);
  }

  updateRule(rule: CardRule) {
    this.$emit("update", rule);
  }

  createRule() {
    this.$emit("create");
  }

  lender(id: number) {
    const lender = this.lenders.find(lender => lender.id == id);

    if (!lender) return "-";

    return lender.name;
  }

  employmentStatus(id: number) {
    const employmentStatus = this.employmentStatusCatalog.find(
      employmentStatus => employmentStatus.id == id
    );

    if (!employmentStatus) return "-";

    return employmentStatus.name;
  }

  employmentTimeWorked(id: number) {
    const employmentTimeWorked = this.employmentTimeWorkedCatalog.find(
      employmentTimeWorked => employmentTimeWorked.id == id
    );

    if (!employmentTimeWorked) return "-";

    return employmentTimeWorked.name;
  }

  cardType(id: number) {
    const cardType = this.cardTypes.find(cardType => cardType.id == id);

    if (!cardType) return "-";

    return cardType.name;
  }
}
