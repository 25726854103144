
import { Component, Vue } from "vue-property-decorator";
import { BCollapse, VBToggle } from "bootstrap-vue";
Vue.component("b-collapse", BCollapse);
Vue.directive("b-toggle", VBToggle);

/**
 * class TheSideBar
 * @extends {Vue}
 */
@Component
export default class TheSideBar extends Vue {
  get currentMenuIndex() {
    return this.$store.getters.currentMenuIndex || "1";
  }

  get collapseSidebar() {
    return sessionStorage.getItem("collapsed") == "true"
      ? true
      : this.$store.getters.collapseSidebar;
  }
}
