import Auth from "../api/helpers/Auth";
import { Gondor, PasswordCredentials } from "../api/Gondor";

interface Action {
  commit: (
    type: string,
    payload?: Function | object | void,
    options?: object
  ) => void;
}

const actions = {
  [Auth.Action.REQUEST]: async (
    { commit }: Action,
    data: PasswordCredentials
  ) => {
    try {
      commit(Auth.Action.REQUEST);
      const resp = await Gondor.login(data);
      commit(Auth.Action.SUCCESS, resp);
      return resp;
    } catch (err) {
      commit(Auth.Action.ERROR, err);
      throw err;
    }
  },
  [Auth.Action.LOGOUT]: async ({ commit }: Action) => {
    commit(Auth.Action.LOGOUT);
    await Gondor.logout();
  },
  [Auth.Action.ME]: async ({ commit }: Action) => {
    try {
      commit(Auth.Action.ME);
      const resp = await Gondor.me();
      return resp;
    } catch (err) {
      commit(Auth.Action.ERROR, err);
      throw err;
    }
  },
};

export default actions;
