export interface ErrorObject {
  code: string;
  title: string;
  description: Record<string, string | number | boolean>;
  field: string;
  line: number;
  column: number;
  value: any;
  errors: Array<any>;
  details: Array<any>;
}
export interface ProgressObject {
  phase: string;
  status: string;
  percentage: number;
  errors: Array<ErrorObject>;
}

export enum Variants {
  SUCCESS = "success",
  INFO = "info",
  DANGER = "danger",
}

export enum Status {
  COMPLETED = "Completed",
  FAILED = "Failed",
  IN_PROGRESS = "In progress",
}

export enum Phases {
  WRITE = "Write",
  ADD_TO_QUEUE = "Add to queue",
  VALIDATION = "Validation",
  UPLOAD = "Upload",
}
