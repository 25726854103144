
import { Component, Vue } from "vue-property-decorator";
import Regex from "@/utils/regex";
import Notify from "@/utils/notifications";
import Auth from "@/api/helpers/Auth";
import { PasswordCredentials } from "@/api/Gondor";

@Component
export default class Login extends Vue {
  regex: Regex = Regex;
  isFormValid = false;
  email = "";
  password = "";

  handleSubmit() {
    const body: PasswordCredentials = {
      username: this.email,
      password: this.password,
    };
    this.$store
      .dispatch(Auth.Action.REQUEST, body)
      .then(() => {
        Notify.successful("Inicio de sessión exitoso.");
        this.$router.push("/");
      })
      .catch(error => {
        if (typeof error.response !== "undefined") {
          Notify.error(
            "Verifica tus credenciales e intenta nuevamente.",
            "Credenciales incorrectas."
          );
        } else {
          Notify.gebServerError({
            response: { data: { message: "", data: { errors: [error] } } },
          });
        }
      });
  }
  loading() {
    return (
      this.$store.getters.loginStatus === "loading" ||
      this.$store.getters.authenticated
    );
  }
  validateInputs() {
    const email = document.querySelector("#form-email");
    const emailFlag =
      email === null ? false : email.className.indexOf("is-valid") > -1;

    const password = document.querySelector("#form-password");
    const passwordFlag =
      password === null ? false : password.className.indexOf("is-valid") > -1;

    this.isFormValid = emailFlag && passwordFlag;
  }
  updated() {
    this.validateInputs();
  }
}
