import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import Home from "../views/Home.vue";
import { Gondor } from "../api/Gondor";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    beforeEnter: (to: Route, from: Route, next: (to?: object) => void) => {
      if (Gondor.SESSION.isAuthenticated()) {
        return next({ path: "/" });
      }
      return next();
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "/lead",
        name: "lead",
        component: () => import("../views/Lead.vue"),
      },
      {
        path: "/lead/upload",
        name: "lead-upload",
        component: () => import("../views/LeadUpload.vue"),
      },
      {
        path: "/card",
        name: "card",
        component: () => import("../views/Card.vue"),
      },
      {
        path: "/card/cost",
        name: "card-cost",
        component: () => import("../views/CardCost.vue"),
      },
      {
        path: "/card/new",
        name: "card-new",
        component: () => import("../views/CardWizard.vue"),
      },
      {
        path: "/api",
        name: "api",
        component: () => import("../views/API.vue"),
      },
      {
        path: "/api/details/:id",
        name: "api-details",
        component: () => import("../views/ApiDetails.vue"),
      },
      {
        path: "/file/duplicate",
        name: "duplicate-lines",
        component: () => import("../views/FileDuplicate.vue"),
      },
      {
        path: "/file/split",
        name: "split",
        component: () => import("../views/FileSplit.vue"),
      },
      {
        path: "/landing",
        name: "landing",
        component: () => import("../views/Landing.vue"),
      },
      {
        path: "/catalog",
        name: "catalog",
        component: () => import("../views/Catalog.vue"),
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("../views/Setting.vue"),
      },
      {
        path: "/inventory",
        name: "inventory",
        component: () => import("../views/inventory/Home.vue"),
        redirect: "/inventory/devices",
        children: [
          {
            path: "/inventory/devices",
            name: "inventory-devices",
            component: () => import("../views/inventory/Devices.vue"),
          },
          {
            path: "/inventory/contributors",
            name: "inventory-contributors",
            component: () => import("../views/inventory/Contributors.vue"),
          },
          {
            path: "/inventory/contributors/:id",
            name: "inventory-contributors-details",
            component: () =>
              import("../views/inventory/ContributorsDetails.vue"),
          },
          {
            path: "/inventory/devices/:id",
            name: "inventory-devices-details",
            component: () => import("../views/inventory/Details.vue"),
          },
        ],
      },
      {
        path: "/liink",
        name: "liink-home",
        component: () => import("../views/Liink/Home.vue"),
        redirect: "/liink/leads",
        children: [
          {
            path: "/liink/leads",
            name: "liink-leads",
            component: () => import("../views/Liink/Leads.vue"),
          },
          {
            path: "/liink/lenders",
            name: "liink-lenders",
            component: () => import("../views/Liink/Lenders.vue"),
          },
          {
            path: "/liink/loan-rules",
            name: "liink-loan-rules",
            component: () => import("../views/Liink/LoanRules.vue"),
          },
          {
            path: "/liink/debt-rules",
            name: "liink-debt-rules",
            component: () => import("../views/Liink/DebtRules.vue"),
          },
          {
            path: "/liink/card-rules",
            name: "liink-card-rules",
            component: () => import("../views/Liink/CardRules.vue"),
          },
        ],
      },
      {
        path: "/sms",
        name: "sms",
        component: () => import("../views/sms/SMSHome.vue"),
        redirect: "/sms/campaign",
        children: [
          {
            path: "/sms/campaign",
            name: "sms-campaign",
            component: () => import("../views/sms/SMSCampaign.vue"),
          },
          {
            path: "/sms/campaign/:id",
            name: "sms-campaign-details",
            component: () => import("../views/sms/SMSCampaignDetails.vue"),
          },
          {
            path: "/sms/journey",
            name: "sms-journey",
            component: () => import("../views/sms/SMSJourney.vue"),
          },
          {
            path: "/sms/templates",
            name: "sms-templates",
            component: () => import("../views/sms/SMSTemplates.vue"),
          },
          {
            path: "/sms/history",
            name: "sms-history",
            component: () => import("../views/sms/SMSHistory.vue"),
          },
        ],
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("../views/Faq.vue"),
      },
      {
        path: "/notification",
        name: "notification",
        component: () => import("../views/Notification.vue"),
      },
      {
        path: "/operations",
        name: "operations",
        component: () => import("../views/operations/Home.vue"),
        redirect: "/operations/setting",
        children: [
          {
            path: "/operations/setting",
            name: "operations-setting",
            component: () => import("../views/operations/Setting.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Gondor.SESSION.isAuthenticated()) {
      return next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      return next();
    }
  } else {
    return next();
  }
});

export default router;
